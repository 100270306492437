import {
    sunset5WithoutVibrantYellow,
    sunset11,
    grey11,
    Color,
    getLinearColorStops,
    setDeckGLAlpha,
    ColorRange
} from '@methanesat/colors';
import { kgHrKm2 } from '../types';
import { ScaleLinear, scaleLinear, ScaleThreshold } from 'd3-scale';

export const AREA_EMISSION_PALETTE = sunset5WithoutVibrantYellow;

/**
 * Area emissions data point ranges.  Max value correlates to *largest physically possible* kg/hr/km2 value.
 * This enables us to tell the story "is this data point dirty compared to other data points?"
 */
export const EMISSION_COLOR_RANGE_MIN: kgHrKm2 = 0;
export const EMISSION_COLOR_RANGE_MAX: kgHrKm2 = 24;
export const GRIDDED_EMISSIONS_SCALE = [EMISSION_COLOR_RANGE_MIN, EMISSION_COLOR_RANGE_MAX];

/**
 *
 * TARGETS
 *
 * Target ranges.  Max value correlates to the basin with the highest *average* kg/hr/km2.
 * This enables us to tell the story "is this target dirty compared to other targets?"
 * Note: right now dirtiest is defined as highest mean kg/kg/hr (mean = total emissions / ground area).  In the future
 * we may be able to define dirtiest as highest methane intensity (intensity = total emissions / total production in
 * barrels of oil equivalent).
 */

/** Color range applied to target gauge and mapped targets */
export const TARGET_PALETTE = sunset11;
// The summarized palette is used for icons to show a subset of the target palette
export const SUMMARIZED_TARGET_PALETTE = [
    TARGET_PALETTE[0],
    TARGET_PALETTE[1],
    TARGET_PALETTE[3],
    TARGET_PALETTE[6],
    TARGET_PALETTE[10]
];
export const TARGET_PALETTE_MISSING_DATA = grey11;
export const TARGET_PALETTE_RANGES = {
    MAIR: {
        MIN: 10_000,
        MAX: 140_000
    },
    MSAT: {
        MIN: 0,
        MAX: 500_000 /** Via Kira on Slack Oct 16: "Ritesh said they expect the top end to be between 300K and 500K kg/hour" */
    }
};
export const TARGET_GREY_DATA = '#808080';

export const targetColorStopCount = 5;

/**
 * We want to use a logarithmic scale so that there are decreased distances
 * between colors changes at lower flux values, and increased distances at
 * higher values. This allows users to visually disambiguate between low level
 * emission targets (ranges in the tens) from high level emitters (ranges in
 * the hundreds) simultaneously.
 * For this reason the distinguishing colors are placed a long a logarithmic
 * scale. Such that exp(N/x) = 100. Where N is the number colors in the palette
 * minus 1.
 * x solves N/ln(100). Then for n values 0 - N, each step solves exp(n/x).
 * Therefore, for 5 steps we are solving exp(n/0.869)
 */
export const FIVE_STOP_LOG_SCALE = [1, 3, 10, 32, 100]; // these are percentages 1 - 100%
export const FIVE_STOP_LINEAR_SCALE = [100 / 24, 25, 50, 75, 100]; // these are percentages 1 - 100%
export const COLOR_STOPS = FIVE_STOP_LINEAR_SCALE.map((n: number) => (n * EMISSION_COLOR_RANGE_MAX) / 100).reverse();

// Concentrations have ranges around 1800 and 2400 for preliminary data
export const CONCENTRATION_RANGE = [1880, 2280];
export const CONCENTRATION_COLOR_STOPS_LOG = FIVE_STOP_LOG_SCALE.map((n: number) => {
    const step = n === 1 ? 0 : n; // Given the min is not 0 by default, start scale at min
    const low = CONCENTRATION_RANGE[0];
    const high = CONCENTRATION_RANGE[1];
    const dif = high - low;
    // For more information on data normalizations see: https://www.indeed.com/career-advice/career-development/normalization-formula
    // Specifically: "Normalization formula for custom ranges"
    return low + step * (dif / 100); // rescale on range
}).reverse();

export enum RASTER_COLORMAP_NAMES {
    msatFlirLinear = 'msat_flir_linear',
    msatFlirLog = 'msat_flir_log',
    msatRainbowLog = 'msat_rainbow_log'
}

export const TITILER_COLORMAP = RASTER_COLORMAP_NAMES.msatFlirLinear;

export const DEFAULT_TARGET_FILL_COLOR: Color = [0, 0, 0, 0];

/** domain (array of color stop thresholds) to pass to d3 scale */
export const TARGET_DOMAIN = getLinearColorStops(11, TARGET_PALETTE_RANGES.MAIR.MIN, TARGET_PALETTE_RANGES.MAIR.MAX);

export const makeLinearColorScale = (domain: number[], colors: ColorRange): ScaleLinear<Color, Color> => {
    return scaleLinear<Color, Color>().domain(domain).range(colors);
};

export const getColorFromScale = (value: number, scale: ScaleThreshold<number, Color> | ScaleLinear<Color, Color>) => {
    return scale(value);
};

/** d3 linear color scale function for targets */
export const TARGET_COLOR_SCALE = makeLinearColorScale(TARGET_DOMAIN, TARGET_PALETTE);

/**
 * Returns a color value, given a TargetFeature with the totalkKgHr property
 */
export function getTargetColor(totalKgHr: number | undefined) {
    const color = getColorFromScale(totalKgHr || 0, TARGET_COLOR_SCALE);
    return color;
}

export const getFilledTargetColor = (totalMethane?: number) => {
    return setDeckGLAlpha(getTargetColor(totalMethane), 255 * 0.85);
};
