import { greaterThanMaxNumber } from './utils';

/**
 * Reads the Airbrake project id and converts it to an integer.
 * @example
 * getAirbrakeProjectId()
 * // returns 285686846
 */
const getAirbrakeProjectId = () => {
    const rawId = process.env.NEXT_PUBLIC_AIRBRAKE_PROJECT_ID;
    if (rawId && !greaterThanMaxNumber(rawId)) {
        const possibleId = parseFloat(rawId);
        const isPositive = possibleId >= 0;

        if (Number.isInteger(possibleId) && isPositive) {
            return possibleId;
        }
    }
};

export const AIRBRAKE_PROJECT_ID = getAirbrakeProjectId();

// This length is currently twice the size of the current key
const ARBITRARY_MAX_KEY_LENGTH = 64;

/**
 * Reads the Airbrake project key and verifies that it isn't too large.
 * @example
 * getAirbrakeProjectKey('f4gjl4')
 * // returns 'f4gjl4'
 */
const getAirbrakeProjectKey = () => {
    const rawKey = process.env.NEXT_PUBLIC_AIRBRAKE_PROJECT_KEY;
    return rawKey && rawKey.length < ARBITRARY_MAX_KEY_LENGTH ? rawKey : undefined;
};

export const AIRBRAKE_PROJECT_KEY = getAirbrakeProjectKey();
