import { envToJWT } from './utils';

/**
 * Reads the Mapbox token and verifies that it is a JWT in the proper format.
 * https://docs.mapbox.com/api/accounts/tokens/#token-format
 * @example
 * getMapboxToken()
 * // returns 'pk....'
 */
const getMapboxToken = () => {
    const possibleToken = envToJWT(process.env.NEXT_PUBLIC_MAPBOX_TOKEN);
    return possibleToken?.startsWith('pk.') ? possibleToken : undefined;
};

export const MAPBOX_TOKEN = getMapboxToken();
