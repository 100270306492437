/**
 * Returns an array of unique strings sorted alphabetically. The sort is also case insensitive.
 *
 * @example
 * getSortedUniqueStrings(['z', 'z', 'a', 'A'])
 * // returns ['a', 'A', 'z']
 */
export const getSortedUniqueStrings = (strings: string[]): string[] => {
    const uniqueStrings = new Set<string>();
    strings.forEach((s) => uniqueStrings.add(s));
    return Array.from(uniqueStrings).sort(stringSortComparison);
};

/**
 * Returns -1, 0, or 1 depending on the ordering of the input strings. -1 means the first variable
 * comes before the second variable in ascending order. 1 means the second variable comes before
 * the first variable in ascending order. 0 means both values are equal in ordering.
 *
 * @example
 * stringSortComparison('b', 'a')
 * // returns 1
 *
 * @example
 * ['the', 'quick', 'brown', 'fox'].sort(stringSortComparison);
 * // returns ['brown', 'fox', 'quick', 'the']
 *
 * @example
 * [
 *  {text: 'the', id: 1},
 *  {text: 'quick', id: 2},
 *  {text: 'brown', id: 3},
 *  {text: 'fox', id: 4}
 * ].sort((a, b) => stringSortComparison(a.text, b.text));
 *
 * // returns [
 * //   {text: 'brown', id: 3},
 * //   {text: 'fox', id: 4},
 * //   {text: 'quick', id: 2},
 * //   {text: 'the', id: 1}
 * // ]
 *
 */
export const stringSortComparison = (a: string, b: string) =>
    a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
