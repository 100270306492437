import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ThemeMode, Theme, ThemeOptions } from '../types';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        drawer: true;
    }
}

/**
 * MUI theme.
 * Note that default palette options only requires the `main` key, as `light`, `dark` and `contrastText`
 * values are generated automatically.
 * Note: Custom colors defined below require augmenting TypeScript types in `@mui/material/styles/createPalette`
 * See https://mui.com/material-ui/customization/palette/
 */
export default class ThemeManager {
    private mode: ThemeMode;

    // Using verbose syntax because of storybook issue
    // https://github.com/storybookjs/storybook/issues/13834
    constructor(mode: ThemeMode = ThemeMode.light) {
        this.mode = mode;
    }

    getTheme(): Theme {
        return this.makeTheme(this.themeOptions());
    }

    makeTheme(themeOptions: ThemeOptions = {}): Theme {
        return responsiveFontSizes(createTheme(themeOptions));
    }

    private themeOptions(): ThemeOptions {
        const prefersDarkMode = this.prefersDarkMode();
        const defaultMaterialTheme = this.makeTheme({ palette: { mode: this.mode } });

        // pull out variables that may need to be re-used in the custom theme
        const palette = {
            action: {
                hover: prefersDarkMode ? defaultMaterialTheme.palette.grey[800] : defaultMaterialTheme.palette.grey[300]
            },
            mode: this.mode,
            primary: {
                main: prefersDarkMode ? '#ffffff' : '#171717'
            },
            secondary: {
                main: prefersDarkMode ? '#171717' : '#ffffff'
            },
            error: {
                main: prefersDarkMode ? '#e26666' : '#d50000'
            },
            warning: {
                main: prefersDarkMode ? '#ffe366' : '#ffd600'
            },
            info: {
                main: prefersDarkMode ? '#66cfe2' : '#00b8d4'
            },
            success: {
                main: prefersDarkMode ? '#66da92' : '#00c853'
            },
            /** Custom color deepPurple */
            deepPurple: {
                main: prefersDarkMode ? '#9a66f1' : '#6200ea'
            },
            /** Custom color purple */
            purple: {
                main: prefersDarkMode ? '#c766ff' : '#aa00ff'
            },
            /** Custom color yellow */
            yellow: {
                main: prefersDarkMode ? '#2d2f1b' : '#f8fdbd'
            },
            /** Custom light gray color */
            lightGray: {
                main: prefersDarkMode ? '#222222' : '#efefef'
            },
            /** Custom progress bar color */
            progressBar: {
                dark: prefersDarkMode ? '#ffffff' : '#171717',
                light: prefersDarkMode ? '#7f7f7f' : '#a6a6a6'
            }
        };
        const shape = {
            borderRadius: 2,
            modalArrowSize: 12
        };

        return {
            breakpoints: {
                values: {
                    xs: 0,
                    drawer: 400,
                    sm: 600,
                    md: 900,
                    lg: 1200,
                    xl: 1536
                }
            },
            button: {
                active: {
                    main: prefersDarkMode ? '#dedede' : '#c1c1c1'
                }
            },
            mixins: {
                mapModalShadow:
                    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12),0px -8px 9px -5px rgba(0,0,0,0.2),0px -15px 22px 2px rgba(0,0,0,0.14),0px -6px 28px 5px rgba(0,0,0,0.12)',
                speedDial: {
                    height: defaultMaterialTheme.spacing(7),
                    width: defaultMaterialTheme.spacing(7),
                    bottom: defaultMaterialTheme.spacing(4),
                    right: defaultMaterialTheme.spacing(2)
                },
                chip: {
                    height: '35px',
                    borderRadius: '18px'
                },
                srOnly: {
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: '1px',
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    whiteSpace: 'nowrap',
                    width: '1px'
                },
                mapButton: {
                    backgroundColor: palette.secondary.main,
                    /** Adds extra border to increase contrast with base map background. */
                    borderColor: defaultMaterialTheme.palette.grey[700],
                    borderRadius: shape.borderRadius,
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    boxShadow: defaultMaterialTheme.shadows[5],
                    color: defaultMaterialTheme.palette.getContrastText(palette.secondary.main),
                    zIndex: defaultMaterialTheme.zIndex.speedDial,
                    '&:hover, &:focus, &:focus-within': {
                        backgroundColor: palette.action.hover,
                        color: defaultMaterialTheme.palette.getContrastText(palette.action.hover)
                    },
                    transition: defaultMaterialTheme.transitions.create('transform', {
                        duration: defaultMaterialTheme.transitions.duration.standard,
                        easing: defaultMaterialTheme.transitions.easing.easeInOut
                    }),
                    transitionProperty: 'background-color'
                }
            },
            palette,
            shape,
            // By default, headings are EXTREMELY large. Copied these defaults from the material ui docs
            // page: https://github.com/mui-org/material-ui/blob/e724d98eba018e55e1a684236a2037e24bcf050c/docs/src/modules/branding/BrandingRoot.tsx#L143
            typography: {
                h1: {
                    fontWeight: 700,
                    letterSpacing: `${this.roundTo5Digits(-2 / 72)}em`,
                    lineHeight: 1.111,
                    marginTop: defaultMaterialTheme.spacing(4),
                    fontSize: defaultMaterialTheme.typography.pxToRem(44)
                },
                h2: {
                    fontWeight: 700,
                    letterSpacing: `${this.roundTo5Digits(-1.5 / 52)}em`,
                    lineHeight: 1.154,
                    fontSize: defaultMaterialTheme.typography.pxToRem(40)
                },
                h3: {
                    fontWeight: 700,
                    letterSpacing: `${this.roundTo5Digits(-1 / 36)}em`,
                    lineHeight: 1.222,
                    fontSize: defaultMaterialTheme.typography.pxToRem(28)
                },
                h4: {
                    fontWeight: 700,
                    letterSpacing: `${this.roundTo5Digits(-0.5 / 24)}em`,
                    lineHeight: 1.25,
                    fontSize: defaultMaterialTheme.typography.pxToRem(24)
                },
                h5: {
                    fontWeight: 600,
                    letterSpacing: `${this.roundTo5Digits(-0.25 / 20)}em`,
                    lineHeight: 1.3,
                    fontSize: defaultMaterialTheme.typography.pxToRem(20)
                },
                h6: {
                    fontWeight: 500,
                    letterSpacing: `${this.roundTo5Digits(-0.25 / 20)}em`,
                    lineHeight: 1.3,
                    fontSize: defaultMaterialTheme.typography.pxToRem(16)
                },
                button: {
                    fontWeight: 600,
                    fontSize: defaultMaterialTheme.typography.pxToRem(16),
                    lineHeight: 1.25
                },
                subtitle1: {
                    fontSize: defaultMaterialTheme.typography.pxToRem(19)
                },
                body1: {
                    fontSize: defaultMaterialTheme.typography.pxToRem(16)
                },
                body2: {
                    fontSize: defaultMaterialTheme.typography.pxToRem(13),
                    lineHeight: defaultMaterialTheme.typography.pxToRem(18)
                }
            },
            components: {
                MuiAccordion: {
                    styleOverrides: {
                        root: {
                            '&.MuiPaper-root.Mui-expanded': {
                                '&:not(:first-of-type):before': {
                                    opacity: 1,
                                    display: 'block'
                                }
                            }
                        }
                    }
                },
                MuiAppBar: {
                    defaultProps: {
                        color: 'default'
                    }
                },
                MuiCard: {
                    defaultProps: { raised: true }
                },
                // Custom text selection styles
                // https://material-ui.com/customization/globals/#global-css
                MuiCssBaseline: {
                    styleOverrides: (theme) => {
                        return {
                            '::selection': {
                                color: prefersDarkMode ? theme.palette.common.black : theme.palette.common.white,
                                backgroundColor: prefersDarkMode
                                    ? theme.palette.common.white
                                    : theme.palette.common.black
                            },
                            /*
                             * User preference: tone down animations.
                             * See https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion and
                             * https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/
                             */
                            '@media screen and (prefers-reduced-motion: reduce), (update: slow)': {
                                ':root': {
                                    animationDuration: '0.001ms !important',
                                    animationIterationCount: '1 !important',
                                    transitionDuration: '0.001ms !important'
                                }
                            }
                        };
                    }
                },
                MuiLink: {
                    defaultProps: {
                        underline: 'none',
                        color: 'inherit',
                        variant: 'body1'
                    },
                    styleOverrides: {
                        root: {
                            touchAction: 'pan-y'
                        }
                    }
                },
                MuiPaper: {
                    defaultProps: { elevation: 3 }
                }
            }
        };
    }

    // Helper function copied from material-ui docs:
    // https://github.com/mui-org/material-ui/blob/e724d98eba018e55e1a684236a2037e24bcf050c/docs/src/modules/branding/BrandingRoot.tsx#L55
    private roundTo5Digits(value: number) {
        return Math.round(value * 1e5) / 1e5;
    }

    private prefersDarkMode(): boolean {
        return this.mode === ThemeMode.dark;
    }
}
