import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import type { RootState } from '../store';
import { AppDispatch } from '../store';

// These typed versions of useDispatch and useSelector should be used for convenience,
// so types don't need to be imported into every component.
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export * from './data';
export * from './breakpoints';
export * from './internationalization';
export * from './pages';
export * from './routing';
export * from './debugger';
