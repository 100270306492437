import { _flatten as flatten, Layer, LayersList, PickingInfo } from '@deck.gl/core';
import { MVTLayer, TileLayer } from '@deck.gl/geo-layers';
import { Tile2DHeader } from '@deck.gl/geo-layers/dist/tileset-2d';
import { GeoJsonLayer } from '@deck.gl/layers';
import { log } from '@methanesat/log';
import { MultiMVTSubLayerProps, MultiMVTSubLayerTile, ParsedMvtTile } from '../types';

import type { Feature } from 'geojson';

/**
 * Render data formatted as [Mapbox Vector Tiles](https://docs.mapbox.com/vector-tiles/specification/).
 *
 * Extension of [MVTLayer class](https://github.com/visgl/deck.gl/blob/df13c83d464296122457685c69b91f2abdcbf79e/modules/geo-layers/src/mvt-layer/mvt-layer.ts) allows layer to use custom renderSublayers method while highlighting features by id correctly across multiple tiles.
 *
 * This subclass does not currently support `props.binary = true`
 * See https://methanesat.atlassian.net/browse/DP-1601
 *
 */
export default class MultiMVTLayer extends MVTLayer {
    static layerName = 'MultiMVTLayer';
    renderLayers(): Layer<MultiMVTSubLayerProps> | null | LayersList {
        if (!this.state?.data) return null;
        // called instead of super.renderLayers(), so we can use our own version of the method
        return this.parentRenderLayers();
    }

    private parentRenderLayers(): Layer<MultiMVTSubLayerProps> | null | LayersList {
        if (!this.state.tileset) {
            return [];
        }
        return this.state.tileset.tiles.map((tile: Tile2DHeader) => {
            // cache the rendered layer in the tile
            if (!tile.isLoaded || !tile.content) {
                // nothing to show
            } else if (!tile.layers) {
                const layers = this.renderSubLayers({
                    ...this.props,
                    id: `${this.id}-${tile.id}`,
                    data: tile.content,
                    _offset: 0,
                    tile
                });
                tile.layers = flatten(layers, Boolean) as Layer<
                    MultiMVTSubLayerProps & { tile?: MultiMVTSubLayerTile }
                >[];
                // TODO: handle non-array tile.content. See top of function for ticket.
            }

            if (tile.layers) {
                tile.layers = tile.layers.map((layer) => {
                    const subLayerProps = this.getPropsBySubLayerFromTile(layer as Layer<MultiMVTSubLayerProps>);
                    return layer.clone(subLayerProps);
                });
            }
            return tile.layers;
        });
    }

    getPropsBySubLayerFromTile(subLayerFromTile: Layer<MultiMVTSubLayerProps>): {
        highlightedObjectIndex: number;
        highlightColor: number[] | ((info: PickingInfo) => number[]);
    } {
        return {
            highlightedObjectIndex: this.getIndexOfHighlightedObject(subLayerFromTile.props.data),
            // allow each sublayer to have its own highlightColor
            highlightColor: subLayerFromTile.props.highlightColor
        };
    }

    /**
     * renamed from getHighlightedObjectIndex to avoid typing error for incorrectly extending the class
     * NOTE: Does not handle non-iterable data (binary data)
     * See original code: https://github.com/visgl/deck.gl/blob/df13c83d464296122457685c69b91f2abdcbf79e/modules/geo-layers/src/mvt-layer/mvt-layer.ts#L247
     */
    private getIndexOfHighlightedObject(data: unknown): number {
        const { hoveredFeatureId, hoveredFeatureLayerName } = this.state;
        const { uniqueIdProperty, highlightedFeatureId } = this.props;

        const isHighlighted = isFeatureIdDefined(highlightedFeatureId);
        const isFeatureIdPresent = isFeatureIdDefined(hoveredFeatureId) || isHighlighted;

        if (!isFeatureIdPresent) {
            return -1;
        }

        const featureIdToHighlight = isHighlighted ? highlightedFeatureId : hoveredFeatureId;

        // Iterable data
        if (Array.isArray(data)) {
            return data.findIndex((feature) => {
                const isMatchingId = getFeatureUniqueId(feature, uniqueIdProperty) === featureIdToHighlight;
                const isMatchingLayer = isHighlighted || getFeatureLayerName(feature) === hoveredFeatureLayerName;
                return isMatchingId && isMatchingLayer;
            });
        }
        // TODO: handle  non-iterable data (binary data). See top of function for ticket.
        // See also original code: https://github.com/visgl/deck.gl/blob/df13c83d464296122457685c69b91f2abdcbf79e/modules/geo-layers/src/mvt-layer/mvt-layer.ts#L247

        return -1;
    }

    renderSubLayers(
        props: Required<TileLayer['props']> & {
            data: ParsedMvtTile;
            _offset: number;
            tile: Tile2DHeader<ParsedMvtTile>;
        }
    ) {
        const subLayers = super.renderSubLayers(props);

        if (this.state.binary && !(subLayers instanceof GeoJsonLayer)) {
            log.warn('renderSubLayers() must return GeoJsonLayer when using binary:true');
        }

        return subLayers;
    }
}

/**
 * Lifted directly from deck.gl
 * https://github.com/visgl/deck.gl/blob/df13c83d464296122457685c69b91f2abdcbf79e/modules/geo-layers/src/mvt-layer/mvt-layer.ts#L417
 */
function getFeatureUniqueId(feature: Feature, uniqueIdProperty: string | undefined) {
    if (feature.properties && uniqueIdProperty) {
        return feature.properties[uniqueIdProperty];
    }

    if ('id' in feature) {
        return feature.id;
    }

    return undefined;
}
/**
 * Lifted directly from deck.gl
 * https://github.com/visgl/deck.gl/blob/df13c83d464296122457685c69b91f2abdcbf79e/modules/geo-layers/src/mvt-layer/mvt-layer.ts#L429
 */
function getFeatureLayerName(feature: Feature): string | null {
    return feature.properties?.layerName || null;
}
/**
 * Lifted directly from deck.gl
 * https://github.com/visgl/deck.gl/blob/df13c83d464296122457685c69b91f2abdcbf79e/modules/geo-layers/src/mvt-layer/mvt-layer.ts#L433
 */
function isFeatureIdDefined(value: unknown): boolean {
    return value !== undefined && value !== null && value !== '';
}
