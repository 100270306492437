import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { black } from '@methanesat/colors';
import { IconLayer, Layer, PolygonLayer, Position } from '@methanesat/maps';

import { centroid, polygon } from '@turf/turf';

import { DEFAULT_TARGET_FILL_COLOR, ZOOM_THRESHOLDS, getFilledTargetColor } from '../../../consts';
import { selectGlobalDate, selectMethaneTargetLayerConfig, selectPlatform, selectTargetDates } from '../../../reducers';
import { RootState } from '../../../store';
import { CaptureFeature } from '../../../types';
import { getDateRangeFromPlatform } from '../../../utils';
import { getCapturesToDisplay, getTimeBoundCaptures, modifySearchCollection } from '../../data';

/**
 * Builds the deck.gl layer for the MSat target layer
 */
export const useTargetLayers = (zoom: number): Layer[] => {
    const {
        highlightedFeatureId,
        highlightedObjectIndex: _highlightedObjectIndex,
        ...layerProps
    } = useSelector(selectMethaneTargetLayerConfig);

    const urlParamsInitialized = useSelector(
        // TODO: this seems like an odd place for this state
        (state: RootState) => state.pages.emissions.selectedFeature.urlParamsInitialized
    );
    const [hasUpdatedLayers, setHasUpdatedLayers] = useState(false);
    const [captures, setCaptures] = useState<CaptureFeature[]>([]);

    // determine time range
    // create new time item "string_from/string_til"
    // modify the search and send
    const globalDate = useSelector(selectGlobalDate);
    const platform = useSelector(selectPlatform);
    const targetDates = useSelector(selectTargetDates);
    const stacSearch = useMemo(() => modifySearchCollection(undefined, platform), [platform]);

    // wait for aync function to load
    // Load data only once when ready
    useEffect(() => {
        // Wait for the URL parameters to be parsed before fetching data. Otherwise, it may
        // fetch layer data with the default date rather than the one from the URL on intial load.
        if (urlParamsInitialized) {
            setHasUpdatedLayers(false);
            const dateRange = getDateRangeFromPlatform(platform);
            getTimeBoundCaptures(new Date(globalDate), stacSearch, dateRange).then((featureList) => {
                if (featureList && featureList.features) {
                    const captures = getCapturesToDisplay(featureList, targetDates);
                    setCaptures(captures);
                    setHasUpdatedLayers(true);
                }
            });
        }
    }, [globalDate, stacSearch, targetDates, urlParamsInitialized, platform]);

    const totalMethane = (d: CaptureFeature) => {
        return d.properties.net_total;
    };

    const showFilledTarget = (d: CaptureFeature) => {
        return totalMethane(d);
    };

    const ICON_MAPPING = {
        marker: { x: 0, y: 0, width: 62, height: 63 }
    };

    const multiCapture: CaptureFeature[] = [];
    captures.forEach((capture) => {
        if (capture.properties.monthlyCaptureCount > 1) {
            multiCapture.push(capture);
        }
    });

    const multipleCaptureIcon = new IconLayer({
        data: hasUpdatedLayers ? multiCapture : undefined,
        getIcon: () => 'marker',
        getPosition: (d) => {
            const targetPolygon = polygon(d.geometry.coordinates);
            const targetCentroid = centroid(targetPolygon);
            return targetCentroid.geometry.coordinates as Position;
        },
        getSize: 48,
        iconAtlas: '/img/multi-target-icon.svg',
        iconMapping: ICON_MAPPING,
        id: layerProps.id + 'multiple-capture-icon',
        minZoom: 6,
        pickable: true,
        visible:
            zoom > ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_TARGET_MULTI_CAPTURE_ICON &&
            zoom < ZOOM_THRESHOLDS.MAXIMUM_ZOOM_LEVEL_TARGET_MULTI_CAPTURE_ICON
    });

    const getLineWidth = (d: CaptureFeature) => {
        if (d.id === highlightedFeatureId) {
            return 3;
        }
        return 1;
    };

    const targetOutlines = new PolygonLayer<CaptureFeature>({
        ...layerProps,
        data: hasUpdatedLayers ? captures : undefined,
        filled: true,
        getFillColor: (d) => {
            /**
             * Many flights don't yet have a value for total methane, which is needed to color-code captures.
             * For these flights we show an empty box which conveys to the user "this is pending data and will get
             * filled in later".
             */
            return showFilledTarget(d) ? getFilledTargetColor(totalMethane(d)) : DEFAULT_TARGET_FILL_COLOR;
        },
        getLineColor: black,
        getLineWidth: getLineWidth,
        getPolygon: (d) => d?.geometry.coordinates,
        lineWidthMaxPixels: 5,
        lineWidthMinPixels: 1,
        lineWidthUnits: 'pixels',
        stroked: true,
        updateTriggers: { getLineWidth: highlightedFeatureId }
    });

    return [targetOutlines, multipleCaptureIcon];
};
