import { log } from '@methanesat/log';

const getStringified = (key: string) => {
    return window.localStorage.getItem(key) || undefined;
};

export const getFromLocalStorage = (key: string): unknown => {
    try {
        if (!window) {
            return undefined;
        }
    } catch {
        log.info('No local storage on server');
        return undefined;
    }
    try {
        const value = getStringified(key);
        if (!value) {
            return undefined;
        }
        return JSON.parse(value);
    } catch {
        log.error(`Unable to retrieve ${key} from local storage`);
        return undefined;
    }
};

export const setInLocalStorage = <Value>(key: string, value: Value) => {
    try {
        if (!window) {
            return undefined;
        }
    } catch {
        log.info('No local storage on server');
        return undefined;
    }
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch {
        log.error(`Unable to set ${key} in local storage`);
    }
};

export const removeFromLocalStorage = (key: string) => {
    try {
        if (!window) {
            return undefined;
        }
    } catch {
        log.info('No local storage on server');
        return undefined;
    }
    try {
        window.localStorage.removeItem(key);
    } catch {
        log.error(`Unable to remove ${key} from local storage`);
    }
};
