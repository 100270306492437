import GeoJSON from 'geojson';

import { WebMercatorViewport } from '@deck.gl/core';

import { MapViewState } from '../types';
import { toDecimalPlaces } from './numbers';

export function getBoundingBox(viewState: MapViewState): GeoJSON.BBox {
    const viewport = new WebMercatorViewport(viewState);
    const [west, north] = viewport.unproject([0, 0]).map((v) => toDecimalPlaces(v, 8)) as [number, number];
    const [east, south] = viewport.unproject([viewport.width, viewport.height]).map((v) => toDecimalPlaces(v, 8)) as [
        number,
        number
    ];
    return [west, south, east, north];
}

/**
 *
 * @param viewState - deck.gl view state
 * @param adjustments - adjustments to the bounding box, relative to the view state, in pixels
 * @returns - GeoJSON bounding box
 *
 * @example
 * // gets a bounding box, original centered at 0 N, 0 W on a screen 800x1000 pixels
 * // the eastern edge is adjusted inward 100 pixels
 * getAdjustedBoundingBox({ lat: 0, lng: 0, zoom: 3, height: 800, width: 1000}, { e: -100 })
 */
export function getAdjustedBoundingBox(
    viewState: MapViewState & { height?: number; width?: number },
    adjustments: { w?: number; s?: number; e?: number; n?: number } = { w: 0, s: 0, e: 0, n: 0 }
): GeoJSON.BBox {
    if (!viewState.height || !viewState.width)
        return [viewState.longitude, viewState.latitude, viewState.longitude, viewState.latitude];

    const viewport = new WebMercatorViewport(viewState);

    const w = adjustments.w || 0;
    const s = adjustments.s || 0;
    const e = adjustments.e || 0;
    const n = adjustments.n || 0;

    const adjustedCenterPixels = [(viewState.width - w + e) / 2, (viewState.height + s - n) / 2];
    const [adjustedCenterLongitude, adjustedCenterLatitude] = viewport
        .unproject(adjustedCenterPixels)
        .map((v) => toDecimalPlaces(v, 8));

    const adjustedHeight = viewState.height + s + n;
    const adjustedWidth = viewState.width + w + e;

    const adjustedViewport = new WebMercatorViewport({
        ...viewState,
        longitude: adjustedCenterLongitude,
        latitude: adjustedCenterLatitude,
        height: adjustedHeight,
        width: adjustedWidth
    });

    const [west, north] = adjustedViewport.unproject([0, 0]).map((v) => toDecimalPlaces(v, 8)) as [number, number];
    const [east, south] = adjustedViewport
        .unproject([adjustedWidth, adjustedHeight])
        .map((v) => toDecimalPlaces(v, 8)) as [number, number];

    return [west, south, east, north];
}
