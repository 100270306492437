import { flatten } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Color, pickingFilterHighlightColor, RGBA, white } from '@methanesat/colors';
import { GeoJsonLayer, MultiMVTLayer, ParsedMvtTile, TextLayer, Tile2DHeader } from '@methanesat/maps';

import {
    basinHighlightColor,
    LAYER_ERRORS_ENABLED,
    maxZoomOGITiles,
    pipelineHighlightColor,
    pointInfrastructureHighlightColor,
    ZOOM_THRESHOLDS
} from '../../../consts';
import {
    addEmissionsMapNotification,
    removeEmissionsMapNotification,
    selectEmissionsMapInfraCategoryFilter,
    selectEmissionsMapLayerConfigs,
    selectInfrastructureTileLayerConfig,
    setOGILayerConfig
} from '../../../reducers';
import {
    ClusteredPointOGITileFeature,
    isBasinOGITileFeature,
    isClusteredPointOGITileFeature,
    isLineOGITileFeature,
    isPointOGITileFeature,
    LineOGITileFeature,
    NothingHighlighted,
    OGILayerIds,
    PolygonOGITileFeature,
    PointOGITileFeature
} from '../../../types';
import type { RootState } from '../../../store';
import { useTranslate } from '../../internationalization';
import { AlertSeverity } from '@methanesat/ui-components';
import { cleanInfraCount, fetchForLayers } from '../../../utils';

/**
 * Builds the deck.gl layer for infrastructure
 */
export const useInfrastructureTileLayer = () => {
    const dispatch = useDispatch();
    const t = useTranslate();
    const selectedHighlightedFeatureId = useSelector(
        (state: RootState) => selectEmissionsMapLayerConfigs(state)[OGILayerIds.tileInfrastructure].highlightedFeatureId
    );

    // function to set redux state from within MultiMVTileLayer when it finishes loading data in the viewport
    const onViewportLoad = useCallback(
        (tiles: Tile2DHeader[]) => {
            // get all features from all tiles, remove falsy features
            const data = flatten(tiles.map((tile) => tile.content).filter((data) => !!data));
            const highlightedObject =
                data.find((feature: GeoJSON.Feature) => feature.id === selectedHighlightedFeatureId) || null;

            dispatch(
                setOGILayerConfig({
                    layerId: OGILayerIds.tileInfrastructure,
                    layerUpdates: { highlightedObject: highlightedObject }
                })
            );
        },
        [selectedHighlightedFeatureId]
    );

    const selectedOgiCategories = useSelector(selectEmissionsMapInfraCategoryFilter);
    const selectedOgiCategoryIds = selectedOgiCategories.map(({ id }) => id);

    const {
        basinVisibility,
        categories,
        companyColorByOgim,
        data,
        categoryIds: _categoryIds,
        highlightedFeatureId,
        ogimFeatures,
        pipelineConfig,
        pipelineInfraVisibility,
        pointInfraConfig,
        pointInfraVisibility,
        polygonInfraConfig,
        visible
    } = useSelector(selectInfrastructureTileLayerConfig);

    return new MultiMVTLayer({
        autoHighlight: false,
        binary: false,
        data,
        highlightedFeatureId: Array.isArray(highlightedFeatureId)
            ? NothingHighlighted.highlightedFeatureId
            : highlightedFeatureId,
        id: OGILayerIds.tileInfrastructure,
        minZoom: ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_BASINS,
        maxZoom: maxZoomOGITiles,
        pickable: true,
        visible,
        fetch: fetchForLayers,
        updateTriggers: {
            renderSublayers: [
                basinVisibility,
                categories,
                pointInfraVisibility,
                pipelineInfraVisibility,
                highlightedFeatureId,
                selectedOgiCategories
            ]
        },
        onViewportLoad,
        onTileLoad: () => {
            if (LAYER_ERRORS_ENABLED) dispatch(removeEmissionsMapNotification(t('emissionsMapPage.error.ogiLayer')));
        },
        onTileError: () => {
            if (LAYER_ERRORS_ENABLED)
                dispatch(
                    addEmissionsMapNotification({
                        isSnackbar: true,
                        message: t('emissionsMapPage.error.ogiLayer'),
                        notificationVisible: true,
                        read: false,
                        severity: AlertSeverity.error,
                        snackbarOpen: true,
                        store: false
                    })
                );
        },
        renderSubLayers: (props) => {
            // render sublayers for each loaded tile
            const { tile, data } = props;
            if (!data || typeof data === 'string') return null;
            if (!Array.isArray(data)) return null;
            props.autoHighlight = false;

            const pipelineData = (data as ParsedMvtTile)
                .filter(isLineOGITileFeature)
                .filter((d) => selectedOgiCategoryIds.includes(d.properties.layerName));
            const pointData = (data as ParsedMvtTile)
                .filter(isPointOGITileFeature)
                .filter((d) => selectedOgiCategoryIds.includes(d.properties.layerName));
            const polygonData = data.filter(isBasinOGITileFeature);

            /** note: remove casting `as string` for all color accessors for all layers
             * once PR https://github.com/visgl/deck.gl/pull/8006 is merged, published &
             * we update the deck.gl version.
             */

            // we need to exclude _offset from the props passed
            // to the text layer for clustered points
            const { _offset, ...restSublayerProps } = props;
            return [
                new GeoJsonLayer<PolygonOGITileFeature>({
                    ...props,
                    data: polygonData,
                    filled: false,
                    getFillColor: polygonInfraConfig.getFillColor,
                    getLineColor: (d) => {
                        // If we are filtering by specific ogim, color them
                        if (ogimFeatures.length > 0) {
                            return companyColorByOgim[d.id];
                        }
                        return Array.isArray(highlightedFeatureId) && highlightedFeatureId.includes(d.id as string)
                            ? (pickingFilterHighlightColor(
                                  polygonInfraConfig.getLineColor as RGBA,
                                  basinHighlightColor
                              ) as Color)
                            : polygonInfraConfig.getLineColor;
                    },
                    getLineWidth: 1000,
                    highlightColor: basinHighlightColor,
                    id: `basin-infrastructure-${tile.id}`,
                    lineWidthMaxPixels: 10,
                    lineWidthMinPixels: 2,
                    visible: basinVisibility
                }),
                new GeoJsonLayer({
                    ...props,
                    data: pointData,
                    getFillColor: (d: PointOGITileFeature | ClusteredPointOGITileFeature) => {
                        // If we are filtering by specific ogim, color them
                        if (ogimFeatures.length > 0) {
                            return companyColorByOgim[d.id];
                        }
                        const baseColor = pointInfraConfig.getFillColor as Color;

                        return Array.isArray(highlightedFeatureId) && highlightedFeatureId.includes(d.id as string)
                            ? (pickingFilterHighlightColor(baseColor, pointInfrastructureHighlightColor) as Color)
                            : baseColor;
                    },
                    getLineColor: (d: PointOGITileFeature | ClusteredPointOGITileFeature) => {
                        // If we are filtering by specific ogim, we color them. This
                        // line makes the different pieces distinguishable
                        if (ogimFeatures.length > 0) {
                            return pointInfraConfig.getLineColor as Color;
                        }

                        const baseColor = pointInfraConfig.getLineColor as Color;

                        return Array.isArray(highlightedFeatureId) && highlightedFeatureId.includes(d.id as string)
                            ? (pickingFilterHighlightColor(baseColor, pointInfrastructureHighlightColor) as Color)
                            : baseColor;
                    },
                    getLineWidth: (d) => (isClusteredPointOGITileFeature(d) ? 7 : 1),
                    stroked: true,
                    getPointRadius: (d) => (isClusteredPointOGITileFeature(d) ? 23 : 5),
                    highlightColor: pointInfrastructureHighlightColor,
                    id: `point-infrastructure-${tile.id}`,
                    lineWidthUnits: pointInfraConfig.lineWidthUnits,
                    pickable: true,
                    pointRadiusUnits: pointInfraConfig.pointRadiusUnits,
                    visible: pointInfraVisibility
                }),
                new TextLayer({
                    ...restSublayerProps,
                    data: pointData,
                    getPosition: (d) => d.geometry.coordinates,
                    getSize: (d) => (d.properties.point_count >= 1000 ? 12 : 14),
                    getText: (d) => (d.properties.point_count ? cleanInfraCount(d.properties.point_count) : ''),
                    id: `point-infrastructure-text-${tile.id}`,
                    pickable: false,
                    getColor: white,
                    highlightColor: [0, 0, 0, 0],
                    visible: pointInfraVisibility
                }),
                new GeoJsonLayer<LineOGITileFeature>({
                    ...props,
                    data: pipelineData,
                    getFillColor: pipelineConfig.getFillColor,
                    getLineColor: (d) => {
                        // If we are filtering by specific ogim, color them
                        if (ogimFeatures.length > 0) {
                            return companyColorByOgim[d.id];
                        }

                        const baseColor = pipelineConfig.getLineColor as Color;
                        return Array.isArray(highlightedFeatureId) && highlightedFeatureId.includes(d.id as string)
                            ? (pickingFilterHighlightColor(baseColor, pipelineHighlightColor) as Color)
                            : baseColor;
                    },
                    getLineWidth: 100,
                    highlightColor: pipelineHighlightColor,
                    id: `pipeline-infrastructure-${tile.id}`,
                    lineWidthMaxPixels: 12,
                    lineWidthMinPixels: 4,
                    visible: pipelineInfraVisibility
                })
            ];
        }
    });
};
