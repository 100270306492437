import isJWT from 'validator/lib/isJWT';
import toBoolean from 'validator/lib/toBoolean';

/**
 * Helper to convert strings containing booleans to booleans
 *
 * @example
 * envToBoolean('tRuE')
 * // returns true
 */
export const envToBoolean = (environmentVariable: string | undefined) =>
    !!environmentVariable && toBoolean(environmentVariable, true);

// Adding a max bound to the string so a very large string can't be entered
const ARBITRARY_MAX_TOKEN_LENGTH = 2000;

/**
 * Utility to verify that environment variables are JWTs and then
 * returns them. Will return undefined if it is not a valid JWT.
 *
 * @example
 * envToBoolean('eyJhbG...') // Assume valid JWT
 * // returns 'eyJhbG...'
 */
export const envToJWT = (environmentVariable: string | undefined) =>
    environmentVariable && environmentVariable.length < ARBITRARY_MAX_TOKEN_LENGTH && isJWT(environmentVariable)
        ? environmentVariable
        : undefined;

/**
 * Utility to verify that stringified numbers are less than the
 * value of the Number.MAX_SAFE_INTEGER. We can store large numbers as
 * strings, but we will cannot perform math operations on large numbers.
 *
 * @example
 * greaterThanMaxNumber('1')
 * // returns false
 */
export const greaterThanMaxNumber = (stringifiedNumber: string) => {
    const stringifiedMaxNumber = `${Number.MAX_SAFE_INTEGER}`;

    return stringifiedNumber.length >= stringifiedMaxNumber.length && stringifiedNumber > stringifiedMaxNumber;
};

// This length was chosen to make sure strings were of a reasonable size
const ARBITRARY_MAX_STRING_LENGTH = 2000;

/**
 * Returns a string if it is reasonably sized
 *
 * @example
 * envToReasonablySizedString('test')
 * // returns 'test'
 */
export const envToReasonablySizedString = (
    inputString: string | undefined,
    length: number = ARBITRARY_MAX_STRING_LENGTH
) => (inputString !== undefined && inputString.length <= length ? inputString : undefined);

/**
 * Checks for any required env variables that are undefined.  Useful for logging a clear
 * error message for debugging.  Returns the keys of undefined entries.
 * @example
 * getUndefinedRequired({ requiredVal: undefined, anotherRequiredVal: 123 });
 * // -> ['requiredVal']
 */
export const getUndefinedRequired = (required: Record<string, unknown>) => {
    const undefinedEntries = Object.entries(required).filter(([, val]) => typeof val === 'undefined');

    return undefinedEntries.map(([key]) => key);
};
