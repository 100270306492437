import { Color, RGB, RGBA } from '../types';

// opaque colors
export const black: Color = [0, 0, 0]; // #000000
export const creamYellow: Color = [250, 250, 168]; // #fafaa8
export const grey: Color = [200, 200, 200]; // #c8c8c8
export const greyWhite: Color = [238, 248, 250]; // #eef8fa
export const royalBlue: Color = [29, 52, 132, 255]; // #1D3484
export const periwinkle: Color = [85, 107, 187, 255]; // #556BBB
export const white: Color = [255, 255, 255]; // #ffffff
export const darkGrey: Color = [100, 100, 100]; // #646464
export const lightGrey: Color = [240, 240, 240]; // #f0f0f0
export const goldenYellow: RGB = [253, 213, 0]; // #fdd500

// RGBA black
export const blackRGBA0: Color = [0, 0, 0, 0]; // #00000000
export const blackRGBA38: Color = [0, 0, 0, 99]; // #00000061

// 50% transparent colors
export const whiteTransparent50: Color = [255, 255, 255, 127]; // #ffffff80
export const orangeTransparent50: Color = [222, 168, 90, 127]; // #dea85a80
export const skyBlueTransparent50: Color = [57, 150, 255, 127]; // #3996ff80
export const periwinkleTransparent50: Color = [152, 175, 255, 127]; // #98afff80

// other transparent colors
export const goldenYellowTransparent39: RGBA = [...goldenYellow, 100]; // #fdd50063
export const whiteTransparent60: RGBA = [255, 255, 255, 153]; // #ffffff99

// ogi color
export const darkTealTransparent60: RGBA = [12, 25, 26, 153]; // #0c191a
export const darkTealTransparent20: RGBA = [12, 25, 26, 51];

// ogi categorical colors
export const hotPink: Color = [254, 144, 250]; // #FE90FA
export const brightGreen: Color = [1, 215, 35]; // #01D723
export const peacockBlue: Color = [3, 169, 244]; // #03A9F4
export const categoricalColors = {
    hotPink,
    brightGreen,
    peacockBlue
};
