import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { TypedStartListening } from '@reduxjs/toolkit';

import {
    decreaseEmissionsMapViewStateZoom,
    increaseEmissionsMapViewStateZoom,
    SLOW_selectEmissionsMapViewState,
    setOperatorFilter
} from '../reducers';
import type { RootState } from '../store';
import { AppDispatch } from '../store';
import { analytics } from '../utils';

// Create the middleware instance and methods
export const analyticsMiddleware = createListenerMiddleware();

// Help from https://redux-toolkit.js.org/api/createListenerMiddleware#typescript-usage
const startAnalyticsListening = analyticsMiddleware.startListening as TypedStartListening<RootState, AppDispatch>;

startAnalyticsListening({
    actionCreator: decreaseEmissionsMapViewStateZoom,
    effect: ({ payload }, listenerApi) => {
        const currentZoom = SLOW_selectEmissionsMapViewState(listenerApi.getOriginalState()).zoom;
        analytics.clickZoom({ direction: 'out', zoom: Math.floor(currentZoom - payload) });
    }
});

startAnalyticsListening({
    actionCreator: increaseEmissionsMapViewStateZoom,
    effect: ({ payload }, listenerApi) => {
        const currentZoom = SLOW_selectEmissionsMapViewState(listenerApi.getOriginalState()).zoom;
        analytics.clickZoom({ direction: 'in', zoom: Math.floor(currentZoom + payload) });
    }
});

startAnalyticsListening({
    actionCreator: setOperatorFilter,
    effect: ({ payload }) => {
        // Track the ids as strings for easier manipulation in Amplitude
        analytics.filterOperators({ operators: payload.map(({ id }) => `${id}`) });
    }
});
