import { combineReducers } from '@reduxjs/toolkit';
import mapInterfaceReducer from './mapInterfaceSlice';
import methaneLayersReducer from './layers/methaneLayersSlice';
import ogiLayersReducer from './layers/ogiLayersSlice';
import globalSettingsReducer from './globalSettingsSlice';
import selectedFeatureReducer from './selectedFeatureSlice';
import viewStateReducer from './viewStateSlice';

const layersReducer = combineReducers({
    methaneLayers: methaneLayersReducer,
    ogiLayers: ogiLayersReducer
});

const emissionsMapReducer = combineReducers({
    layers: layersReducer,
    globalSettings: globalSettingsReducer,
    mapInterface: mapInterfaceReducer,
    selectedFeature: selectedFeatureReducer,
    view: viewStateReducer
});

export default emissionsMapReducer;
export * from './mapInterfaceSlice';
export * from './layers/methaneLayersSlice';
export * from './layers/ogiLayersSlice';
export * from './globalSettingsSlice';
export * from './selectedFeatureSlice';
export * from './viewStateSlice';
