import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const initialState = {
    /** DOM selector of the element to focus the bulls-eye tooltip on */
    targetSelector: '',
    /** DOM selector of the chip containing the targetSelector. The whole chip should be visible on load*/
    parentSelector: '',
    /** Optional text to show in the side tooltip. */
    text: '',
    /** Whether the modal is visible or not. */
    visible: false
};

/**
 * Tooltip modal state reducers
 * This allows the tooltip modal functionality to be called from anywhere in the app.
 */
export const tooltipSlice = createSlice({
    name: 'tooltip',
    initialState,
    reducers: {
        hide: () => {
            return initialState;
        },
        show: (state, action: PayloadAction<{ targetSelector: string; parentSelector?: string; text?: string }>) => {
            const { targetSelector, parentSelector, text } = action.payload;

            state.targetSelector = targetSelector;
            if (parentSelector) {
                state.parentSelector = parentSelector;
            }
            if (text) {
                state.text = text;
            } else {
                state.text = ''; // Clears out previously set text.
            }
            state.visible = true;
        }
    }
});

export default tooltipSlice.reducer;
