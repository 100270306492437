import { IOptions, Notifier } from '@airbrake/browser';

import { LogLevels } from './consts';

/**
 * This type is based on the code that constructs an INotice type here:
 * https://github.com/airbrake/airbrake-js/blob/d02ecc5a5022048d8f63a45f5ae38e2e4569190a/packages/browser/src/base_notifier.ts#L118
 */
interface NotifyOptions {
    message: unknown;
    session?: Record<string, unknown>;
    environment?: Record<string, unknown>;
    params?: Record<string, unknown>;
    context?: Record<string, unknown>;
}

interface FormattedOptions extends Omit<NotifyOptions, 'message'> {
    error: unknown;
}

type CustomNotifier = Pick<Notifier, 'notify'>;

interface LoggerOptions extends Partial<IOptions> {
    useConsole?: boolean;
    userId?: string;
}

class ConsoleAdapter implements CustomNotifier {
    private error(options: FormattedOptions) {
        console.error(options.error);
    }

    private info(options: FormattedOptions) {
        console.info(options.error);
    }

    private log(options: FormattedOptions) {
        console.log(options.error);
    }

    private warn(options: FormattedOptions) {
        console.warn(options.error);
    }

    notify(options: FormattedOptions) {
        const severity = options?.context?.severity;

        switch (severity) {
            case LogLevels.warn:
                this.warn(options);
                break;
            case LogLevels.error:
                this.error(options);
                break;
            case LogLevels.info:
                this.info(options);
                break;
            default:
                this.log(options);
                break;
        }

        return Promise.resolve({});
    }
}

/**
 * AirbrakeAdapter is a wrapper around the Airbrake Notifier.
 * It will only send a manual airbrake if the severity is error.
 */
class AirbrakeAdapter implements CustomNotifier {
    private notifier: Notifier;

    constructor(notifier: Notifier) {
        this.notifier = notifier;
    }

    notify(options: FormattedOptions) {
        const severity = options?.context?.severity;

        if (severity === LogLevels.error) {
            return this.notifier.notify(options);
        }

        return Promise.resolve({});
    }
}

/**
 * Logger is instantiated as a singleton so that all of our packages
 * that use the Logger use the same instance per project. For example,
 * we want the maps packages that uses the Logger to send errors to
 * the app Airbrake project if the the maps package is being used in the
 * app project. If it was used in a different project, let's say mair, for
 * instance, we would want all maps errors to be sent to the mair Airbrake
 * project.
 */
class Logger {
    private _notifiers: CustomNotifier[] = [];
    private _userId: string | undefined;

    init(options: LoggerOptions) {
        this.reset();

        // TODO Add tslib so we can use spread assignment syntax
        // const { useConsole, ...copiedOptions } = options;
        const copiedOptions = { ...options };
        const useConsole = copiedOptions.useConsole;
        delete copiedOptions.useConsole;
        this._userId = copiedOptions.userId;
        delete copiedOptions.userId;

        const projectId = copiedOptions.projectId;
        const projectKey = copiedOptions.projectKey;

        if (projectId !== undefined && projectKey) {
            this._notifiers.push(new AirbrakeAdapter(new Notifier({ ...copiedOptions, projectId, projectKey })));
        }

        if (useConsole) {
            this._notifiers.push(new ConsoleAdapter());
        }
    }

    reset() {
        this._notifiers = [];
    }

    private get notifiers() {
        // If we didn't call init before calling a log method, we'll log to
        // the console
        if (this._notifiers.length <= 0) {
            this.init({ useConsole: true });
        }

        return {
            notify: (options: FormattedOptions) => {
                this._notifiers.forEach((notifier) => {
                    notifier.notify(options);
                });
            }
        };
    }

    private formatOptions(severity: LogLevels, options: NotifyOptions): FormattedOptions {
        const copiedOptions = { ...options };
        const error = copiedOptions.message;
        delete copiedOptions.message;

        return {
            error,
            ...copiedOptions,
            context: { ...copiedOptions.context, severity, ...(this._userId && { analyticsId: this._userId }) }
        };
    }

    private createOptionsFromErrorMessage(message: string): NotifyOptions {
        return { message };
    }

    private inputAdapter<ReturnValue>(fn: (options: NotifyOptions) => ReturnValue) {
        return (optionsOrMessage: NotifyOptions | string) => {
            const options =
                typeof optionsOrMessage === 'string'
                    ? this.createOptionsFromErrorMessage(optionsOrMessage)
                    : optionsOrMessage;

            return fn(options);
        };
    }

    error = this.inputAdapter((options: NotifyOptions) => {
        this.notifiers.notify(this.formatOptions(LogLevels.error, options));
    });

    info = this.inputAdapter((options: NotifyOptions) => {
        this.notifiers.notify(this.formatOptions(LogLevels.info, options));
    });

    warn = this.inputAdapter((options: NotifyOptions) => {
        this.notifiers.notify(this.formatOptions(LogLevels.warn, options));
    });
}

export const log = new Logger();
