import {
    HighlightedObject,
    MapStateLayerNames,
    MethaneLayerIds,
    MethaneLayerState,
    NothingHighlighted,
    OGILayerIds,
    OGILayerState
} from '../types';
import { getRootId } from './pickInfo';

/** Helper function to update the highlights of the Methane and OGI Layers */
export const updateLayerHighlight = <T extends MethaneLayerState | OGILayerState>(params: {
    layerId: MapStateLayerNames;
    objectId: string | null;
    object: HighlightedObject;
    state: T;
    coordinate?: number[];
    index?: number;
}) => {
    const { layerId, objectId, object, state, coordinate, index } = params;
    const layerKeys = Object.keys(state) as Array<keyof T>;

    layerKeys.forEach((key) => {
        const isLayer = key === getRootId(layerId);
        const updatedObject = { ...object, ...(coordinate && { coordinate }), layerId };

        const update = isLayer
            ? {
                  highlightedFeatureId: objectId,
                  highlightedObject: updatedObject,
                  ...(index != null && { highlightedObjectIndex: index })
              }
            : {
                  highlightedFeatureId: NothingHighlighted.highlightedFeatureId,
                  highlightedObject: null,
                  highlightedObjectIndex: -1
              };

        state[key] = {
            ...state[key],
            ...update
        };
    });
};

/** Helper function to clear the highlights of the Methane and OGI Layers */
export const removeLayerHighlights = <T extends MethaneLayerState | OGILayerState>(state: T) => {
    const layerKeys = Object.keys(state) as Array<keyof T>;
    layerKeys.forEach((key) => {
        const highlightByFeatureId =
            key === MethaneLayerIds.plumeEmissionRate ||
            key === MethaneLayerIds.targets ||
            OGILayerIds.tileInfrastructure;

        const update = {
            highlightedObjectIndex: NothingHighlighted.highlightedObjectIndex,
            highlightedObject: null,
            ...(highlightByFeatureId && { highlightedFeatureId: NothingHighlighted.highlightedFeatureId })
        };

        state[key] = {
            ...state[key],
            ...update
        };
    });
};
