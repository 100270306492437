import { formatPercentage } from './numbers';
import { KilogramsPerHour, KilometersSquared } from '../types';

/**
 * Calculates mean emissions per km².
 */
export const toEmissionsKm2 = (emissionsKgHr: KilogramsPerHour, areaKm2: KilometersSquared) =>
    Math.round(emissionsKgHr / areaKm2);

/**
 * Converts confidence interval (CI) into a single percentage, ultimately to be displayed as something like ±20%.  This
 * simplification allows us to remove distractions, simplifying and showing only the numbers necessary to convey
 * uncertainty.  Both low and high confidence intervals are 1 standard deviation from the mean, which means we can
 * compute the output with either high or low CI (instead of needing both numbers).
 * See https://en.wikipedia.org/wiki/Confidence_interval
 * @example
 * getConfidencePercentage(79300, 65900);
 * // -> 17
 */
export const getConfidencePercentage = (kgHr: KilogramsPerHour, confidenceIntervalLowOrHighKgHr: KilogramsPerHour) =>
    formatPercentage(Math.abs(kgHr - confidenceIntervalLowOrHighKgHr), kgHr);
