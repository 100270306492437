import { useEffect, useMemo, useRef, useState } from 'react';

import { log } from '@methanesat/log';

import { LoadedData, MSatPickInfo, OGILayerIds, OGITileFeatureProperties } from '../../types';
import { customFetch, useErrorBoundary } from '../../utils';
import { INFRASTRUCTURE_API_BASE_URL } from '../../environmentVariables';
import { noop } from 'lodash';

export const getOGIMetadataFetchOptions = (featureIds: number[]) => ({
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Prefer: 'params=single-object' },
    body: JSON.stringify({ ogim_id: [...featureIds] })
});

export type FetchOptions = {
    method?: string;
    body?: Blob | ArrayBuffer | DataView | FormData | URLSearchParams | string;
    mode?: RequestMode;
    credentials?: RequestCredentials;
    cache?: RequestCache;
    headers?: HeadersInit;
    redirect?: RequestRedirect;
    referrer?: string;
    referrerPolicy?: ReferrerPolicy;
    integrity?: string;
    keepAlive?: string;
    signal?: AbortSignal;
};

/**
 * A helper hook for useDataAPI. useDataAPI will preserve the fetched data between
 * subsequent calls to keep displayed on a page. For times where we want to
 * display a loading state instead of the last data, this hook will allow us
 * to know when we are fetching for more data.
 * @example
 * useClearedPreviousData({id: 1}, {ogimId: 4})
 * // returns {id: 1}
 */
export const useClearedPreviousData = <Data,>(data: Data, params: Record<string, unknown>) => {
    // Store the previous values so we know what has changed
    // since last render
    const previousDataAndValues = useRef<{
        data?: Data;
        [key: string]: unknown;
    }>({ data, ...params });

    // This gets the key, value pairs of the params. It's used
    // quite extensively throughout this hook.
    const entries = Object.entries(params);

    // If the parameter keys have changed since last time, we throw an
    // error so we don't need to worry about handling parameters that
    // may sometimes appear. Disappearing parameters shouldn't be a
    // feature we need in the near future.
    const keyInPreviousStore = entries.every(([key]) => Object.hasOwn(previousDataAndValues.current, key));
    if (!keyInPreviousStore) {
        throw new Error('params are not allowed to change');
    }

    // useMemo will memoize the value from the function. It is
    // only called with the data or the values of the parameters have
    // changed
    return useMemo(() => {
        // Check to see if the parameter values are the same
        const allParamsMatch = entries.every(([key, value]) => previousDataAndValues.current[key] === value);

        if (allParamsMatch) {
            // If the parameters are the same, the data could
            // be different. We assign and then return the updated data.
            // This signals that data fetching has completed.
            previousDataAndValues.current.data = data;
        } else {
            // If the parameters are different, then we need to signal
            // that fetching has begun by returning undefined for the data.
            // We then update the previous values for subsequent checks.
            previousDataAndValues.current.data = undefined;
            entries.forEach(([key, value]) => {
                previousDataAndValues.current[key] = value;
            });
        }

        return previousDataAndValues.current.data;
    }, [data, entries.map(([, value]) => value)]);
};

/**
 * Returns data for a given url.
 */
export const useDataAPI = function <Data = LoadedData | LoadedData[]>(
    dataUrl: string | null,
    { disableErrorBoundary, ...options }: FetchOptions & { disableErrorBoundary?: boolean } = {},
    errorHandler: (e: unknown) => void = noop
): Data | undefined {
    /**
     * assign a generic, empty object to the state initially. should be okay
     * for both geojson and arrays of datapoints.
     */
    const [loadedData, setLoadedData] = useState<Data | undefined>();

    // Used to activate the error boundary surrounding the component
    const { showErrorBoundary } = useErrorBoundary();
    const modifiedErrorHandler = disableErrorBoundary ? errorHandler : showErrorBoundary;

    /**
     * update loadedData if/when the url changes
     * and add/create new controller
     */
    useEffect(() => {
        const controller = new AbortController();
        async function getData(dataUrl: string | null) {
            if (!dataUrl) return;

            // load data & set
            try {
                const res = await customFetch(dataUrl, {
                    // pass controller.signal to options to allow aborting the request
                    signal: controller.signal,
                    ...options
                });

                if (!res.ok) {
                    throw new Error(`Network request at ${res.url} failed with status code ${res.status}`);
                }

                const jsonData = await res.json();
                setLoadedData(jsonData);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    log.warn(`useDataAPI aborted request at url ${dataUrl}\n${e}`);
                } else {
                    // The only time we want to disable the error boundary is if
                    // we are fetching data for the deckgl layers. This is because
                    // deckgl doesn't provide a nice interface for ErorrBoundaries and
                    // their own layers.
                    if (disableErrorBoundary) {
                        log.error(`useDataAPI failed for url ${dataUrl}\n${e}`);
                    }
                    modifiedErrorHandler(e);
                }
            }
        }

        getData(dataUrl);

        // clean up the last request
        return () => {
            controller.abort();
        };
    }, [dataUrl]);

    return loadedData;
};

export const useOGIAPI = function <Data extends OGITileFeatureProperties>(
    info: MSatPickInfo<GeoJSON.Geometry, OGITileFeatureProperties | Data>
): { data: Data; loadingState?: boolean } | null {
    const objectId = info?.object.id;
    const layerId = info?.layer.id;
    const shouldFetch = layerId.includes(OGILayerIds.tileInfrastructure);

    const tileInfrastructureUrl = useMemo(() => {
        // check for valid objectId
        if (typeof objectId !== 'number' || Number.isNaN(Number(objectId))) return null;

        return `${INFRASTRUCTURE_API_BASE_URL}/rpc/feature_meta_data`;
    }, [objectId]);

    const fetchedMetadata = useDataAPI<Data[]>(
        (shouldFetch && tileInfrastructureUrl) || null,
        getOGIMetadataFetchOptions([Number(objectId)])
    );
    const mergedMetadata = fetchedMetadata && {
        ...fetchedMetadata[0],
        ...info.object.properties
    };

    let loadingState;

    if (typeof fetchedMetadata === 'undefined' && typeof tileInfrastructureUrl === 'string') {
        loadingState = true;
    }

    const dataAndLoadingState = { loadingState: loadingState, data: mergedMetadata };

    /**
     * If the clicked layer isn't the OGI tile layer, the data is static
     * and therefore already includes all the metadata available. Run-time
     * typing isn't possible, so ignoring the type error on the next line
     * is necessary.
     */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return shouldFetch ? dataAndLoadingState || null : { ...dataAndLoadingState, data: info.object.properties };
};
