import { scaleLinear } from 'd3-scale';

/**
 * Returns a scale function that scales a value linearly. Used with zoom to figure out how
 * to scale the size of a plume in a way that looks nice to the user.
 * @example
 * const scale = getPlumeRadiusRateOfChangeScale([2, 1, 8, 10], 100, 200);
 * scale(1);
 * -> 100
 */
export const getPlumeRadiusRateOfChangeScale = (unsortedDomain: number[], minRange: number, maxRange: number) => {
    return scaleLinear()
        .domain([Math.min(...unsortedDomain), Math.max(...unsortedDomain)])
        .range([minRange, maxRange])
        .clamp(true);
};
