/**
 * Custom CSS styling to account for mobile safari bug https://methanesat.atlassian.net/browse/DP-701
 * It uses 100vh as a fallback if the browser doesn't support css variables
 */
export const ONE_HUNDRED_VH_STYLING = ['100vh', '100dvh'];

/**
 * MethaneSAT metadata, for social media sharing, SEO, etc.
 */
export const methaneSATMeta = {
    description:
        "A unique high-precision satellite with a unique purpose: To reduce the methane emissions responsible for more than a quarter of today's human-caused warming.",
    image: 'https://www.methanesat.org/files/2019/07/MethaneSAT-Share_Large-1200x630.jpg'
};

/**
 * Detects if the user prefers reduced motion, which should be respected with content that's animated
 * normally.
 *
 * Note: will not update when the media query changes (requires page refresh).
 */
export const prefersReducedMotion =
    typeof window === 'object' &&
    typeof window.matchMedia === 'function' &&
    window?.matchMedia('(prefers-reduced-motion: reduce)').matches;

// @todo (dcalhoun) Move to i18n strings.
export const UNKNOWN = 'unknown';
