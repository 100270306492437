import GeoJSON from 'geojson';
import { layerZoomThresholds, ZOOM_THRESHOLDS } from '../../../../consts';
import { MapStateLayerNames, MethaneLayerIds, OGILayerIds } from '../../../../types';
import { RootState } from '../../../../store';
import { getAdjustedBoundingBox } from '@methanesat/maps';

const selectView = (state: RootState) => state.pages.emissions.view;

const selectViewState = (state: RootState) => selectView(state).viewState;

type Threshold = {
    minimum?: number;
    maximum?: number;
};
export function isWithinZoomBounds<Names extends string = MapStateLayerNames>(
    zoom: number,
    layerId: Names | null,
    thresholds: { [P in Names]?: Threshold } = layerZoomThresholds
) {
    if (!layerId) return false;
    const layerThreshold = thresholds[layerId];

    // if no zoom thresholds are set up for a given layer,
    // assume the layer can be visible at all zoom levels
    if (!layerThreshold) return true;

    let { minimum, maximum } = layerThreshold;
    // default maximum zoom level for deck.gl is 20
    // see https://deck.gl/docs/api-reference/core/map-view#view-state
    if (!maximum || maximum <= Number(minimum)) maximum = 20;
    if (!minimum || minimum >= maximum) minimum = 0;
    // Using less than the maximum instead of less than or equal to so
    // the zoom thresholds cached selector works
    return zoom >= minimum && zoom < maximum;
}

/**
 * A selector that returns the zoom, floored. Using this selector minimizes the amount of
 * unnecessary rerenders, improving performance.
 */
export const selectFlooredZoom = (state: RootState) => Math.floor(selectViewState(state).zoom);

// The unique zoom thresholds sorted in descending order
const descendingZoomThresholds = Array.from(new Set(Object.values(ZOOM_THRESHOLDS))).sort((a, b) => b - a);

/**
 * Returns the zoom thresholds where layers start appearing and disappearing. This is a
 * performance optimization.
 * @example
 * selectZoomThresholds({pages: {emissions: {viewState: {zoom: 4.6}}}})
 * // returns 4.5 (assuming 4.5 is a threshold in ZOOM_THRESHOLDS)
 */
export const selectZoomThresholds = (state: RootState) => {
    const zoom = selectViewState(state).zoom;

    // The idea is, given a zoom, take the closest threshold than is less than or equal to it.
    // We only return values that are important to the code calling this selector.
    let nearestThreshold = 0;
    for (const threshold of descendingZoomThresholds) {
        if (zoom >= threshold) {
            nearestThreshold = threshold;
            break;
        }
    }
    return nearestThreshold;
};

// Subscribing to the redux store through this selector will take a performance
// hit as the component will rerender with every movement of the map.
// Before using this selector, determine how precise you need the zoom value to be
// in order to implement your functionality. If your zoom value does not need to be precise,
// consider making an imprecise selector for you needs or using on that already exists,
// such as selectFlooredZoom or selectZoomThresholds.
export const SLOW_selectEmissionsMapViewState = (state: RootState) => state.pages.emissions.view.viewState;

// Select the zoom to a hundreth to minimize the amount of renders in other selectors
export const selectZoomAtHundreth = (state: RootState) => {
    const zoom = SLOW_selectEmissionsMapViewState(state).zoom;
    return Math.round(zoom * 100) / 100;
};

export const selectEmissionsMapBBox = (state: RootState) => selectView(state).bbox;

/**
 * This prevents us needing to call SLOW_selectEmissionsMapViewState in a component directly.
 * This in turn prevents the component from re-rendering every time the viewState changes.
 */
export const makePerformantSelectAdjustedBBox = () => {
    let cache: GeoJSON.BBox;
    let previousBbox: GeoJSON.BBox;
    let previousRightWidthOffset: number;

    return (state: RootState, rightWidthOffset: number, bbox: GeoJSON.BBox) => {
        const updateValue = previousRightWidthOffset !== rightWidthOffset || bbox !== previousBbox;
        if (!cache || updateValue) {
            previousBbox = bbox;
            previousRightWidthOffset = rightWidthOffset;
            const viewState = SLOW_selectEmissionsMapViewState(state);
            cache = getAdjustedBoundingBox(viewState, { e: rightWidthOffset });
        }
        return cache;
    };
};

// Selectors for layer visibility

// l4 data as grid
export const selectAreaEmissionsWithinZoom = (state: RootState) => {
    const zoom = selectFlooredZoom(state);
    return isWithinZoomBounds(zoom, MethaneLayerIds.areaEmissionRaster);
};

export const selectPlumeFluxWithinZoom = (state: RootState) => {
    const zoom = selectFlooredZoom(state);
    return isWithinZoomBounds(zoom, MethaneLayerIds.plumeEmissionRate);
};

// infrastructure
export const selectTargetWithinZoom = (state: RootState) => {
    const zoom = selectFlooredZoom(state);
    return isWithinZoomBounds(zoom, MethaneLayerIds.targets);
};

// selectors for the combined infrastructure layers (api & static files)
// that are treated as single layers in the map controls UI
//
export const selectInfrastructureWithinZoom = (state: RootState) => {
    const zoom = selectFlooredZoom(state);
    return isWithinZoomBounds(zoom, OGILayerIds.pointInfrastructure) || isWithinZoomBounds(zoom, OGILayerIds.pipelines);
};

export const selectBasinWithinZoom = (state: RootState) => {
    const zoom = selectFlooredZoom(state);
    return isWithinZoomBounds(zoom, OGILayerIds.basins) || isWithinZoomBounds(zoom, OGILayerIds.pipelines);
};
