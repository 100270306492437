import { lngLatToWorld, worldToLngLat } from '@math.gl/web-mercator';

import { CogInfo } from '../types';

/**
 * An adapter for the getBoundingBoxOfAreaEmission function that converts
 * CogInfo into the cog input for the function.
 */
export const extractCogInfo = ({
    bounds: [west, south, east, north],
    width,
    height
}: Pick<CogInfo, 'bounds' | 'width' | 'height'>) => {
    const northWest = [west, north];
    const southEast = [east, south];
    return { height, northWest, southEast, width };
};

interface GeoTIFFParams {
    northWest: number[];
    southEast: number[];
    width: number;
    height: number;
}

/**
 * Area emission cogs are built on a grid such that each point of an area emission
 * rectangle can be derived by taking the distance between the bounding box of
 * the entire GeoTIFF and dividing it by the width and height of the image. This
 * function performs the operations to find an area emission cell within a cog.
 *
 * All map operations need to be performed in the Web Mercator projection as DeckGL
 * uses this projection. Web Mercator has different properties than the WGS 84
 * projection that impact how the area emission bounding box is computed.
 */
export const getBoundingBoxOfAreaEmission = (
    { northWest, southEast, width, height }: GeoTIFFParams,
    longitude: number,
    latitude: number
) => {
    // Convert the northwest and southeast points to Web Mercator
    const [ax, ay] = lngLatToWorld(northWest);
    const [cx, cy] = lngLatToWorld(southEast);

    // Get the distances of each area emission rectangle.
    const widthDistance = Math.abs(cx - ax) / width;
    const heightDistance = Math.abs(cy - ay) / height;

    // Convert the clicked point to Web Mercator
    const [x, y] = lngLatToWorld([longitude, latitude]);

    // Find the west and south bounds of the area emission that
    // encapsulate the clicked point
    const westBound = smallestBound(x, widthDistance, ax);
    const southBound = smallestBound(y, heightDistance, cy);

    // Derive the remaining bounds using the distance
    const eastBound = westBound + widthDistance;
    const northBound = southBound + heightDistance;

    // Create the bounding box for the GeoJSON object and transform the points
    // back to WGS 84, which Deckgl uses as input
    const coordinates = [
        [
            [westBound, southBound],
            [westBound, northBound],
            [eastBound, northBound],
            [eastBound, southBound],
            [westBound, southBound]
        ].map(worldToLngLat)
    ];

    return {
        type: 'Feature' as const,
        geometry: {
            coordinates,
            type: 'Polygon' as const
        }
    };
};

/**
 * Finds the smallest bound that is <= value that is derived by going distance
 * n times. This can be used to generate a bounding box of a certain size
 * around a given point. It is a substitute for the following loop:
 * for (let i = starValue; i < value; i += distance) {...}.
 *
 * @example
 * smallestBound(6, 3, 2)
 * // returns 5 because start (2) + 3 (distance) = 5. One more step,
 * // start (5) + 3 (distance) = 8 which is greater than 6. We want the
 * // smallest bound for the bounding box.
 */
const smallestBound = (value: number, distance: number, startValue: number) => {
    // Find the number of steps we need to multiply distance by to get to
    // a result that is <= value
    const step = Math.floor((value - startValue) / distance);
    // Do the computation to get the result that is <= value
    return distance * step + startValue;
};

/**
 * Derives a bounding box as [west, south, east, north]  from the coordinates of
 * a GeoJSON polygon rectangle
 */
export const getRectangleBBox = (coordinates: number[][]) => {
    const west = Math.min(coordinates[0][0], coordinates[1][0], coordinates[2][0], coordinates[3][0]);
    const south = Math.min(coordinates[0][1], coordinates[1][1], coordinates[2][1], coordinates[3][1]);
    const east = Math.max(coordinates[0][0], coordinates[1][0], coordinates[2][0], coordinates[3][0]);
    const north = Math.max(coordinates[0][1], coordinates[1][1], coordinates[2][1], coordinates[3][1]);

    return [west, south, east, north];
};

/**
 * Checks for the intersection of two bounding boxes. Returns true if they do.
 * Each bounding box is given as [west, south, east, north].
 */
export const checkBBoxIntersection = (bbox1: number[], bbox2: number[]) => {
    return !(
        // bbox2 is left of bbox1
        (
            bbox2[0] > bbox1[2] ||
            // bbox2 is right of bbox1
            bbox2[2] < bbox1[0] ||
            // bbox2 is below bbox1
            bbox2[1] > bbox1[3] ||
            // bbox2 is above bbox1
            bbox2[3] < bbox1[1]
        )
    );
};
