import dayjs from 'dayjs';
import { GIT_BRANCH, GIT_HASH } from './git';
import { envToBoolean, envToReasonablySizedString } from './utils';

/**
 * Validates the build time is a valid JavaScript Date object.
 *
 * @example
 * getBuildDateTime()
 * // returns 'Wed, Nov 9, 2022, 5:22:58 PM EST'
 */
const getBuildDateTime = () => {
    const rawBuildDateTime = process.env.buildTime;

    return rawBuildDateTime && !isNaN(Date.parse(rawBuildDateTime)) ? rawBuildDateTime : undefined;
};

export const BUILD_DATE_TIME = getBuildDateTime();
export const ENVIRONMENT = envToReasonablySizedString(process.env.NEXT_PUBLIC_ENVIRONMENT);

/**
 * Constructs a string for displaying build info in the UI for quick reference internally.
 */
export const getBuildInfoForDisplay = () => {
    let buildInfo = '';
    if (GIT_BRANCH === 'main') {
        // development server
        buildInfo = `Portal dev ${GIT_HASH} (${dayjs(BUILD_DATE_TIME).toISOString()})`;
    } else if (GIT_BRANCH) {
        // staging and production server
        buildInfo = `Portal ${GIT_BRANCH}`;
    } else {
        // localhost development
        buildInfo = `Portal localhost`;
    }

    return buildInfo;
};

/**
 * Flag for custom fetch headers and custom 401 handling, required
 * for deployments running an IAP environment
 */
export const IN_IAP = envToBoolean(process.env.NEXT_PUBLIC_IN_IAP);
