import { Breakpoint, useMediaQuery, useTheme } from '@methanesat/ui-components';

export const useCurrentBreakpoint = () => {
    const theme = useTheme();

    // all breakpoints defined in theme
    const breakpoints: Breakpoint[] = theme.breakpoints.keys;

    // find breakpoint matching the current screen size
    const breakpoint = breakpoints.reduce((previous, current) => {
        const screenMatchesCurrent = useMediaQuery(theme.breakpoints.up(current));
        return screenMatchesCurrent ? current : previous;
    }, 'xs');

    return breakpoint;
};
