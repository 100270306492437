import { useRef, useEffect, MutableRefObject } from 'react';
import { log } from '@methanesat/log';

function logPropDifferences({
    name,
    incomingProps,
    lastProps
}: {
    name: string;
    incomingProps: Record<string, unknown>;
    lastProps: Record<string, unknown>;
}) {
    const allKeys = new Set([...Object.keys(incomingProps), ...Object.keys(lastProps)]);
    allKeys.forEach((key) => {
        const incomingValue = incomingProps[key];
        const lastValue = lastProps[key];
        if (incomingValue !== lastValue) {
            log.info(`${name}: ${key} New Value: ${incomingValue}`);
            log.info(`${name}: ${key} Last Value: ${lastValue}`);
        }
    });
}

/**
 * Hook to help track down un-necessary re-renders due to creating
 * new objects on each render of a component's parent.
 *
 * Pass identifying string and an object containing all props or state to monitor.
 * Can include any props that may change between renders
 * (or props that should *not* change between renders).
 *
 * **Example usage in MapControlsDrawer.tsx:**
 *
 * `useDebugPropChanges('drawer', {StyleButtonProps, ...drawerProps});`
 *
 * On each re-render, if StyleButtonProps change, but appear to be the same
 * on inspection, it is likely that a new object is created on each render
 * of its parent, forcing React to re-render the MapControlsDrawer component.
 *
 */
export function useDebugPropChanges(name: string, incomingProps: Record<string, unknown>) {
    const lastProps: MutableRefObject<Record<string, unknown> | undefined> = useRef();

    useEffect(() => {
        log.info(`Mounted ${name}`);
    }, []);

    if (lastProps.current) {
        logPropDifferences({ name, incomingProps, lastProps: lastProps.current });
    }
    lastProps.current = incomingProps;
}
