import { ApiKey, Environment } from '../ampli';
import { envToBoolean, envToReasonablySizedString } from './utils';

/**
 * Verifies a valid environment is passed in and returns it.
 *
 * @example
 * getAmplitudeEnvironment()
 * // returns development | production
 */
const getAmplitudeEnvironment = () => {
    const rawValue = process.env.NEXT_PUBLIC_AMPLITUDE_ENVIRONMENT;
    let toReturn = undefined;

    if (rawValue) {
        const environments = Object.keys(ApiKey);
        const maxLength = environments.reduce((max, environment) => {
            return environment.length > max ? environment.length : max;
        }, Number.MIN_SAFE_INTEGER);

        if (rawValue.length <= maxLength) {
            const lowercaseEnvironment = rawValue.toLocaleLowerCase();

            if (environments.includes(lowercaseEnvironment)) {
                toReturn = lowercaseEnvironment as Environment;
            }
        }
    }

    return toReturn;
};

export const AMPLITUDE_ENVIRONMENT = getAmplitudeEnvironment();

export const ANALYTICS_LOGS_TO_CONSOLE = envToBoolean(process.env.NEXT_PUBLIC_ANALYTICS_LOGS_TO_CONSOLE);

// Using envToReasonablySizedString because it could be a relative url
export const ANALYTICS_SERVER_URL = envToReasonablySizedString(process.env.NEXT_PUBLIC_ANALYTICS_SERVER_URL);
