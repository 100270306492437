import { createSlice } from '@reduxjs/toolkit';
import { SelectedFeatureState } from '../../../../types';

/** 
 * This slice contains the state variables for the map feature that has been selected when
 * querying from URL parameters, as well as reducers for setting/resetting these variables.
 *
 * Currently, the app only supports querying for methane features (point sources, area emissions,
 * and targets) from the URL. In the future, if we were to support querying for OGI features from
 * URL parameters, then it would have to be updated with the appropriate state variables and reducers
 * to do so. Link to ticket:
   DP-4301: https://methanesat.atlassian.net/browse/DP-4301?atlOrigin=eyJpIjoiMGRlOTVlNzgzOTgxNDE3MjhlNDYyNTI4Y2EwZDYyMDkiLCJwIjoiaiJ9
*/

const initialState: SelectedFeatureState = {
    selectedMethaneFeature: {
        selectedStacCollectionId: '',
        selectedStacItemId: '',
        selectedFeatureType: '',
        selectedFeatureCoordinates: []
    },
    selectedPlume: null,
    urlParamsInitialized: false,
    fetchingDrawerInfo: false
};

export const selectedFeatureSlice = createSlice({
    name: 'selectedFeature',
    initialState,
    reducers: {
        setSelectedFeatureParams: (state, { payload }) => {
            const { collectionId, itemId, feature, coordinates } = payload;
            if (collectionId !== undefined) {
                state.selectedMethaneFeature.selectedStacCollectionId = collectionId;
            }
            if (itemId !== undefined) {
                state.selectedMethaneFeature.selectedStacItemId = itemId;
            }
            if (feature !== undefined) {
                state.selectedMethaneFeature.selectedFeatureType = feature;
            }
            if (coordinates !== undefined) {
                state.selectedMethaneFeature.selectedFeatureCoordinates = coordinates;
            }
        },
        resetSelectedFeatureParams: (state) => {
            state.selectedMethaneFeature.selectedStacCollectionId = '';
            state.selectedMethaneFeature.selectedStacItemId = '';
            state.selectedMethaneFeature.selectedFeatureType = '';
            state.selectedMethaneFeature.selectedFeatureCoordinates = [];
        },
        setSelectedPlume: (state, { payload }) => {
            state.selectedPlume = payload;
        },
        setUrlParamsInitialized: (state) => {
            state.urlParamsInitialized = true;
        },
        setFetchingDrawerInfo: (state, { payload }) => {
            state.fetchingDrawerInfo = payload;
        }
    }
});

// reducers
export const {
    setSelectedFeatureParams,
    resetSelectedFeatureParams,
    setSelectedPlume,
    setUrlParamsInitialized,
    setFetchingDrawerInfo
} = selectedFeatureSlice.actions;

export default selectedFeatureSlice.reducer;
