/**
 * Initial state of the side drawer.
 */
export const initialDrawerState = {
    download: false,
    layerData: false,
    legend: false,
    settings: false,
    noData: false,
    notifications: false
};

/**
 * closed state of the side drawer.
 */
const closedDrawerState = {
    download: false,
    layerData: false,
    legend: false,
    settings: false,
    noData: false,
    notifications: false
};

/**
 * Manages side drawer state.
 */
export function drawerStateReducer(
    _drawers: typeof initialDrawerState,
    action: { type: 'open'; id: keyof typeof initialDrawerState } | { type: 'close' }
) {
    switch (action.type) {
        case 'open':
            return {
                ...closedDrawerState,
                [action.id]: true
            };
        case 'close':
            return closedDrawerState;
        default:
            throw Error('unknown action type', action);
    }
}
