import { createSlice } from '@reduxjs/toolkit';

import { MAIR_DEFAULT_GLOBAL_DATE, PERMIAN_DATE, getDefaultGlobalDate } from '../../../../consts';
import { GlobalSettings, Platforms } from '../../../../types';
import { getMonthEnd } from '../../../../utils';
import {
    zoomToPermianAreaEmissions,
    zoomToPermianPlumeEmissions,
    zoomToPermianTarget,
    zoomToPermianTargetBroad
} from '../emissionsActions';

const initialState: GlobalSettings = {
    date: {
        global: MAIR_DEFAULT_GLOBAL_DATE.value
    },
    platform: Platforms.MAIR
};

export const globalSettingsSlice = createSlice({
    name: 'globalSettingsReducer',
    initialState,
    reducers: {
        setGlobalDate: (state, { payload }: { payload: number }) => {
            if (payload !== state.date.global) {
                // if the global date is changing, overwrite the entire date
                // object - this resets all individual target dates
                state.date = { global: payload };
            }
        },
        setPlatform: (state, { payload }) => {
            state.platform = payload;
        },
        setTargetDate: (state, { payload }: { payload: { date: number; targetId: string } }) => {
            const { date, targetId } = payload;
            const newMonth = getMonthEnd(new Date(date));
            const newMonthNumber = newMonth.getTime();
            state.date.global = newMonthNumber;
            state.date[targetId] = date;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(zoomToPermianAreaEmissions, (state) => {
                state.date.global = PERMIAN_DATE.value;
            })
            .addCase(zoomToPermianPlumeEmissions, (state) => {
                state.date.global = PERMIAN_DATE.value;
            })
            .addCase(zoomToPermianTarget, (state) => {
                state.date.global = PERMIAN_DATE.value;
            })
            .addCase(zoomToPermianTargetBroad, (state) => {
                state.date.global = PERMIAN_DATE.value;
            });
    }
});

export const { setGlobalDate, setPlatform, setTargetDate } = globalSettingsSlice.actions;
export default globalSettingsSlice.reducer;
