import { useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Theme, ThemeMode } from '../types';
import { ThemeManager } from '../utils';

export { useTheme } from '@mui/material/styles';
export { default as useMediaQuery } from '@mui/material/useMediaQuery';
export { createTheme } from '@mui/material/styles';

interface UseMakeThemeProps {
    themeMode?: ThemeMode;
}

/**
 * Creates a Theme based on the user's color scheme preferences or
 * passed in color perference.
 */
export const useMakeTheme = ({ themeMode }: UseMakeThemeProps = {}): Theme => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const colorFromMediaQuery = prefersDarkMode ? ThemeMode.dark : ThemeMode.light;
    const themeColor = themeMode ? themeMode : colorFromMediaQuery;

    return useMemo(() => new ThemeManager(themeColor).getTheme(), [prefersDarkMode]);
};
