import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    areaHighlightColor,
    defaultGeoJsonLayerConfig,
    methaneDiffuseRangeFilterKeys,
    methanePlumeRangeFilterKeys,
    targetHighlightColor
} from '../../../../../consts';
import {
    AreaEmissionsProducts,
    MethaneLayerIds,
    MethaneLayerState,
    MethaneLayerUpdate,
    NothingHighlighted,
    UpdatableEmissionRateLayerConfig
} from '../../../../../types';
import { SHOW_L3_OPTION } from '../../../../../environmentVariables';
import {
    addOneMapFeatureHighlight,
    removeAllMapFeatureHighlights,
    resetOgiNearPlumes,
    setOgiNearPlumes
} from '../../emissionsActions';
import { getRootId, removeLayerHighlights, updateLayerHighlight } from '../../../../../utils';

/**
 * This slice contains the configuration for the map's Methane layers, which include area emissions,
 * plumes, and targets, as well as reducers to update the configs and highlights of the layers.
 */

const initialMethaneLayerState: MethaneLayerState = {
    [MethaneLayerIds.areaEmissionRaster]: {
        enabled: true,
        opacity: 0.3,
        pickable: true,
        filtersArea: methaneDiffuseRangeFilterKeys,
        highlightColor: areaHighlightColor,
        product: AreaEmissionsProducts.l4
    },
    [MethaneLayerIds.plumeEmissionRate]: {
        enabled: true,
        filtersPlume: methanePlumeRangeFilterKeys,
        opacity: 1,
        pickable: true
    },
    [MethaneLayerIds.targets]: {
        ...defaultGeoJsonLayerConfig,
        enabled: true,
        filled: true,
        opacity: 0.8,
        pickable: true,
        stroked: true,
        highlightColor: targetHighlightColor,
        highlightedFeatureId: NothingHighlighted.highlightedFeatureId
    }
};

/**
 * Helper function to determine if a layer ID belongs to the Methane layer state.
 */
export const isMethaneLayerStateKey = (key: string): key is keyof MethaneLayerState => {
    return Object.keys(initialMethaneLayerState).includes(key);
};

export const methaneLayerSlice = createSlice({
    name: 'methaneLayers',
    initialState: initialMethaneLayerState,
    reducers: {
        setL4LayerConfigs: (state, { payload }: { payload: UpdatableEmissionRateLayerConfig }) => {
            const methaneL4LayerIds = [MethaneLayerIds.areaEmissionRaster, MethaneLayerIds.plumeEmissionRate] as const;
            const { enabled, filtersArea, filtersPlume } = payload;
            methaneL4LayerIds.forEach((id) => {
                (state[id] as (typeof state)[typeof id]) = {
                    ...state[id],
                    ...(typeof enabled !== 'undefined' && { enabled }),
                    ...(id === MethaneLayerIds.areaEmissionRaster && filtersArea && { filtersArea }),
                    ...(id === MethaneLayerIds.plumeEmissionRate && filtersPlume && { filtersPlume })
                };
            });
        },
        setAreaEmissionsProduct: (state, { payload }: PayloadAction<AreaEmissionsProducts>) => {
            // only allow changing from the default (L4) if L3 option is enabled
            if (SHOW_L3_OPTION) {
                state[MethaneLayerIds.areaEmissionRaster].product = payload;
            }
        },
        setMethaneLayerConfig: <LayerId extends keyof MethaneLayerState>(
            state: MethaneLayerState,
            { payload }: { payload: MethaneLayerUpdate<LayerId> }
        ) => {
            const { layerId, layerUpdates } = payload;
            if (state[layerId]) {
                state[layerId] = {
                    ...state[layerId],
                    ...layerUpdates
                };
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(resetOgiNearPlumes, (state) => {
                const layer = state[MethaneLayerIds.areaEmissionRaster];
                if (layer) {
                    layer.enabled = true;
                }
            })
            .addCase(setOgiNearPlumes, (state) => {
                state[MethaneLayerIds.areaEmissionRaster].enabled = false;
            })
            /** highlight related reducers */
            .addCase(addOneMapFeatureHighlight, (state, action) => {
                const { layerId } = action.payload;

                // Check if the layerId belongs to methane layers - if not clear methane layer highlights
                if (!isMethaneLayerStateKey(getRootId(layerId))) {
                    removeLayerHighlights<MethaneLayerState>(state);
                    return;
                }
                updateLayerHighlight<MethaneLayerState>({ ...action.payload, state: state });
            })
            .addCase(removeAllMapFeatureHighlights, (state) => {
                removeLayerHighlights<MethaneLayerState>(state);
            });
    }
});

export const { setL4LayerConfigs, setAreaEmissionsProduct, setMethaneLayerConfig } = methaneLayerSlice.actions;
export default methaneLayerSlice.reducer;
