import ReactMarkdown from 'react-markdown';

type ReactMarkdownProps = Omit<Parameters<typeof ReactMarkdown>[0], 'children'>;
interface MarkdownFormatterProps extends ReactMarkdownProps {
    markdown: string;
}

/**
 * Component to convert markdown in the language translations to a formatted string element
 * ReactMarkdown library was chosen because it does not depend on using a dangerouslySetInnerHTML
 */
const MarkdownFormatter = ({ markdown, ...props }: MarkdownFormatterProps) => {
    return (
        <ReactMarkdown unwrapDisallowed disallowedElements={['p']} {...props}>
            {markdown}
        </ReactMarkdown>
    );
};

export default MarkdownFormatter;
