import GeoJSON from 'geojson';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { geographicCenters, initialViewStates, maxZoomBaseMap, ZOOM_THRESHOLDS } from '../../../../consts';
import { Platforms, ViewStatePropsQueryString, ViewState } from '../../../../types';
import {
    setOgiNearPlumes,
    zoomToPermianAreaEmissions,
    zoomToPermianPlumeEmissions,
    zoomToPermianTarget,
    zoomToPermianTargetBroad,
    zoomToPermianOilAndGas
} from '../emissionsActions';

/**
 * This slice handles the state of the emissions map view, including the view state and bounding box.
 * It provides reducers to set the view state, increase/decrease the zoom level, and set the bounding box.
 * Additionally, it includes extra reducers to handle specific actions related to zooming to various geographic areas.
 */
const initialMapViewState: ViewState = {
    viewState: initialViewStates[Platforms.MAIR],
    bbox: [0, 0, 0, 0]
};

export const viewStateSlice = createSlice({
    name: 'viewState',
    initialState: initialMapViewState,
    reducers: {
        setEmissionsMapViewState: (state, { payload }: { payload: ViewStatePropsQueryString }) => {
            const modifiedPayload = (
                Object.entries(payload) as [
                    keyof ViewStatePropsQueryString,
                    ViewStatePropsQueryString[keyof ViewStatePropsQueryString]
                ][]
            ).reduce((newPayload, [key, value]) => {
                let returnValue = newPayload;

                const numVal = Number(value);
                if (!isNaN(numVal)) {
                    returnValue = { ...newPayload, [key]: numVal };
                }

                return returnValue;
            }, {});

            state.viewState = {
                ...state.viewState,
                ...modifiedPayload
            };
        },
        decreaseEmissionsMapViewStateZoom: (state, { payload }: PayloadAction<number>) => {
            const newZoom = state.viewState.zoom - payload;
            state.viewState.zoom = newZoom;
        },
        increaseEmissionsMapViewStateZoom: (state, { payload }: PayloadAction<number>) => {
            let newZoom = state.viewState.zoom + payload;

            // off-by-one correction for google
            // see GoogleBaseMap in maps package
            if (newZoom > maxZoomBaseMap - 1) {
                newZoom = maxZoomBaseMap - 1;
            }
            state.viewState.zoom = newZoom;
        },
        setEmissionsMapBBox: (state, { payload }: { payload: GeoJSON.BBox }) => {
            state.bbox = payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setOgiNearPlumes, (state, action) => {
                const ZOOM_TO_SINGLE_PLUME = 13;
                const { longitude, latitude } = action.payload;
                state.viewState = {
                    ...state.viewState,
                    longitude,
                    latitude,
                    zoom: ZOOM_TO_SINGLE_PLUME
                };
            })
            .addCase(zoomToPermianAreaEmissions, (state) => {
                state.viewState = {
                    ...initialViewStates[Platforms.MAIR],
                    zoom: ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_AREA_FLUX_GRID,
                    ...geographicCenters.permian
                };
            })
            .addCase(zoomToPermianPlumeEmissions, (state) => {
                state.viewState = {
                    ...initialViewStates[Platforms.MAIR],
                    zoom: ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_PLUME_FLUX,
                    ...geographicCenters.permian
                };
            })
            .addCase(zoomToPermianTarget, (state) => {
                state.viewState = {
                    ...initialViewStates[Platforms.MAIR],
                    zoom: ZOOM_THRESHOLDS.MAXIMUM_ZOOM_LEVEL_TARGET - 0.1,
                    ...geographicCenters.permian
                };
            })
            .addCase(zoomToPermianTargetBroad, (state) => {
                state.viewState = {
                    ...initialViewStates[Platforms.MAIR],
                    zoom: initialViewStates[Platforms.MAIR].zoom + 0.5,
                    ...geographicCenters.RF06
                };
            })
            .addCase(zoomToPermianOilAndGas, (state) => {
                state.viewState = {
                    ...initialViewStates[Platforms.MAIR],
                    ...geographicCenters.permian,
                    zoom: ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_OGI + 0.1
                };
            });
    }
});

// reducers
export const {
    setEmissionsMapViewState,
    decreaseEmissionsMapViewStateZoom,
    increaseEmissionsMapViewStateZoom,
    setEmissionsMapBBox
} = viewStateSlice.actions;

export default viewStateSlice.reducer;
