import { CustomCanvasLayerProps } from '@methanesat/charts';

const TITLE_PADDING = 8;

/**
 * Gets access to the chart's canvas context and draws a horizontal
 * dashed line and label representing the target intensity.
 */
export const dashedLine =
    (yCoordinate: number, title: string) =>
    ({ ctx, lineGenerator, yScale, innerWidth }: CustomCanvasLayerProps) => {
        // Draw dashed line
        const canvasLineGenerator = lineGenerator.context(ctx);
        ctx.strokeStyle = 'black';
        ctx.beginPath();
        ctx.setLineDash([10, 10]);
        canvasLineGenerator([
            { x: 0, y: yScale(yCoordinate) },
            { x: innerWidth, y: yScale(yCoordinate) }
        ]);
        ctx.stroke();

        // Draw context text
        ctx.fillStyle = 'black';
        ctx.font = '14px roboto, sans-serif';
        ctx.fillText(title, 0, (yScale(yCoordinate) as number) - TITLE_PADDING);
    };
