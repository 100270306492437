import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../../../../store';

// Global date
export const selectGlobalDate = (state: RootState) => state.pages.emissions.globalSettings.date.global;

// object/dict of target-specific dates
export const selectTargetDates = createSelector([(state) => state.pages.emissions.globalSettings.date], (dates) => {
    const { global: _global, ...targetDates } = dates;
    return targetDates;
});

// Platform selected
export const selectPlatform = (state: RootState) => state.pages.emissions.globalSettings.platform;
