/**
 * Operation used to aggregate all data object weights to calculate a cell's color value (e.g. hexagon cell).
 */
export enum colorAggregationTypes {
    sum = 'SUM',
    mean = 'MEAN',
    min = 'MIN',
    max = 'MAX'
}

/**
 * Operation used to aggregate all data to calculate pixel colors.
 */
export enum heatmapColorAggregationTypes {
    sum = 'SUM',
    mean = 'MEAN'
}

/**
 * Scaling function used to determine the color of the cells in visualizations (e.g. hexagon cell).
 */
export enum colorScaleTypes {
    quantize = 'quantize',
    quantile = 'quantile',
    linear = 'linear',
    ordinal = 'ordinal'
}

/**
 * Operation used to aggregate all data to calculate the elevation size of 3d hexagon cell..
 */
export enum elevationAggregationTypes {
    sum = 'SUM',
    mean = 'MEAN',
    min = 'MIN',
    max = 'MAX'
}
