import { GeoJsonProperties, Geometry, Polygon } from 'geojson';
import { groupBy, isEqual, orderBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { booleanWithin } from '@turf/turf';

import { Color, colorArrayToRgb } from '@methanesat/colors';
import { log } from '@methanesat/log';
import { CalendarData } from '@methanesat/ui-components';

import {
    CONCENTRATION_RANGE,
    dataSources,
    getFilledTargetColor,
    RASTER_COLORMAP_NAMES,
    TARGET_GREY_DATA
} from '../../../consts';
import { selectPlatform } from '../../../reducers';
import {
    AreaEmissionsProducts,
    AreaFluxFeature,
    CaptureFeature,
    CaptureFeatureProperties,
    Platforms,
    STACCollection,
    StacFeature,
    StacSearch,
    StacSearchResponse
} from '../../../types';
import {
    BASIC_SEARCH,
    customFetch,
    FILTER_LANG,
    formatBbox,
    getDatetimeOneMonthRangeString,
    getDatetimeQuarterlyRangeString,
    getIsValidDateString,
    getTargetIDFilter
} from '../../../utils';

/**
 * This file should contain ALL direct calls to STAC so we can
 * easily see which endpoints we are using.
 *
 * Write helper functions here and call them where they are needed.
 */

/**
 * Return urls for emissions data
 * @returns area emissions raster url
 */
const getStacDataSource = () => {
    return dataSources['area-emission-raster'].url;
};

/**
 * Perform a specific Search from STAC
 * @param search StacSearch
 * @returns results from STAC
 */
export const doSTACSearch = async <P = GeoJsonProperties, G extends Geometry = Geometry>(
    search: StacSearch | null
): Promise<StacSearchResponse<G, P> | null> => {
    const bodyStr = JSON.stringify(search);
    const urls = getStacDataSource();
    try {
        const stacResponse = await customFetch(`${urls.targetsUrl}/search`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: bodyStr
        });
        if (stacResponse.status < 200 || stacResponse.status >= 400) {
            log.error(`${stacResponse.status} received from STAC. Could not perform search`);
            return null;
        }
        return stacResponse.json();
    } catch (e) {
        log.error(`Error fetching stac items: ${e}. Could not perform search`);
        return null;
    }
};

// hook wrapper for doSTACSearch
const useDoSTACSearch = (search: StacSearch | null) => {
    const searchRef = useRef<StacSearch | null>(null);
    const [stacData, setStacData] = useState<StacSearchResponse<Geometry, GeoJsonProperties> | null>();
    useEffect(() => {
        const getData = async () => {
            searchRef.current = search;
            const data = await doSTACSearch(search ? search : null);
            setStacData(data);
        };
        if (!isEqual(searchRef.current, search)) {
            getData();
        }
    }, [search]);
    return stacData;
};
/**
 *
 * @returns capture details from STAC bound by time range around given date
 */
export const getBasicSearch = (): StacSearch => {
    return structuredClone(BASIC_SEARCH);
};
export enum DateRanges {
    Month = 'month',
    Quarter = 'quarter'
}
/**
 *
 * @returns capture details from STAC bound by time range around given date
 */
export const getTimeBoundCaptures = async (
    date: Date,
    search: StacSearch,
    dateRange: DateRanges
): Promise<StacSearchResponse<GeoJSON.Polygon, CaptureFeatureProperties> | null> => {
    if (!search) {
        search = getBasicSearch();
    }
    search = structuredClone(search);
    if (dateRange === DateRanges.Month) {
        search.datetime = getDatetimeOneMonthRangeString(date);
    } else if (dateRange === DateRanges.Quarter) {
        search.datetime = getDatetimeQuarterlyRangeString(date);
    } else {
        log.error(`Cannot search using the timeframe ${dateRange}`);
    }
    return doSTACSearch(search);
};

/**
 * Determines collection name based on data processing level and instrument.
 * @param product Data processing level (L3 is concentrations, L4 is emissions).
 * @param platform Whether the instrument is satellite-based or aircraft-based.
 */
export const getCollectionName = (
    product: AreaEmissionsProducts = AreaEmissionsProducts.l4,
    platform: Platforms = Platforms.MSAT
): STACCollection => {
    if (platform === Platforms.MSAT) {
        // MethaneSAT (satellite-based instrument)
        return product === AreaEmissionsProducts.l4
            ? STACCollection.MethaneSAT_Level4
            : STACCollection.MethaneSAT_Level3;
    } else {
        // MethaneAIR (aircraft-based instrument)
        return product === AreaEmissionsProducts.l4
            ? STACCollection.MethaneAIR_Level4
            : STACCollection.MethaneAIR_Level3;
    }
};

/**
 * Modify the collection the search will perform for
 * @param product Data processing level (L3 is concentrations, L4 is emissions).
 * @param platform if you want Sat or Air data (will not provide both as basic search will do this anyway for l4)
 */
export const modifySearchCollection = (
    product: AreaEmissionsProducts = AreaEmissionsProducts.l4,
    platform: Platforms = Platforms.MSAT,
    search?: StacSearch
) => {
    if (!search) {
        search = getBasicSearch();
    }
    search = structuredClone(search);
    search.collections = [getCollectionName(product, platform)];
    return search;
};

type CapturePlusCount = CaptureFeature & { properties: { platformTimespanCaptureCount?: number } };

/**
 * Returns a list of target-captures for which to display data.
 * Returns a single capture/flight per target.
 * If a target has a specific date selected (in @param targetDates),
 * the capture/flight for that date will be returned.
 * If no target-specific date is given, the latest capture will be returned.
 */
export const getCapturesToDisplay = (
    captures: StacSearchResponse<Polygon, CaptureFeatureProperties>,
    targetDates: { [targetId: string]: number | null | undefined }
) => {
    const targetGroups = groupBy(captures.features, (f: CaptureFeature) => f.properties.target_id);
    const result: CapturePlusCount[] = [];
    let selection: CapturePlusCount | undefined;
    for (const [targetId, targetCaptures] of Object.entries(targetGroups)) {
        // get feature to display for each targetId
        if (targetDates[targetId]) {
            // if the target has a specific date set, use it
            selection = targetCaptures.find((f) => {
                const isValidDateString = getIsValidDateString(f.properties.datetime);
                if (isValidDateString && f.properties.datetime) {
                    const captureDate = new Date(f.properties.datetime);
                    const captureDateAtMidnight = captureDate.setUTCHours(0, 0, 0, 0);
                    return captureDateAtMidnight === targetDates[targetId];
                }
                return false;
            });
        } else {
            // otherwise, get the most recent feature
            const sorted: CaptureFeature[] = orderBy(
                targetCaptures,
                (f) => (f.properties.datetime ? new Date(f.properties.datetime) : 0),
                'desc'
            );
            selection = sorted[0];
        }
        if (selection) {
            selection.properties = { ...selection.properties, monthlyCaptureCount: targetCaptures.length };
            result.push(selection);
        }
    }
    return result;
};

/**
 * Modify the item the search will perform for
 * @param itemId the item id to search for
 */
export const modifySearchItem = (itemId: string, search?: StacSearch) => {
    if (!search) {
        search = getBasicSearch();
    }
    search = structuredClone(search);
    search.ids = [itemId];
    return search;
};

/**
 * Modify search params to query around a given bounding box
 * @param bbox bounding box to intersect
 * @param search other search parameters
 * @returns
 */
export const modifySearchByBoundingBox = (bbox: number[][], search?: StacSearch) => {
    if (!search) {
        search = getBasicSearch();
    }
    search = structuredClone(search);
    search.bbox = formatBbox(bbox);
    return search;
};

/**
 * Modify search params to query around a given point
 * @param lng longitude
 * @param lat latitude
 * @param search other serach parameters
 * @returns
 */
export const modifySearchByPoint = (lng: number, lat: number, search?: StacSearch) => {
    if (!search) {
        search = getBasicSearch();
    }
    search = structuredClone(search);
    search.intersects = {
        type: 'Point',
        coordinates: [lng, lat]
    };
    return search;
};

/**
 *
 * @param itemID
 * @param isFlux if returning url for flux or concentration collection
 * @returns
 */
export const getStacItemCOGUrl = (
    itemID: string,
    collectionName: STACCollection = STACCollection.MethaneAIR_Level4,
    colormapName: string | undefined | RASTER_COLORMAP_NAMES = 'colormap_name=magma&rescale=0,1000'
) => {
    const isConcentrations = collectionName.includes('Level3');
    if (isConcentrations) {
        colormapName = RASTER_COLORMAP_NAMES.msatFlirLog;
    }

    /**
     * rescale: sets min-max inputs for linearly outputting to 0-255 color bins.
     * See https://github.com/developmentseed/titiler/discussions/494#discussioncomment-3113805
     * Concentrations (L3) scale is currently optimized for RF06.
     * Emissions (L4) scale is optimized for RF06/RF08 received Feb 2024 (non-DPP GIM)
     */
    const rescale = isConcentrations ? `rescale=${CONCENTRATION_RANGE[0]},${CONCENTRATION_RANGE[1]}` : 'rescale=0,23';
    const urls = getStacDataSource();
    return `${urls.titilerUrl}/collections/${collectionName}/items/${itemID}/tiles/WebMercatorQuad/{z}/{x}/{y}@2x.png?assets=COG&colormap_name=${colormapName}&${rescale}&nodata=nan`;
};

/**
 * Get point source GeoJSON for one stac item
 *
 * TODO: DP-3560 This feels like a potential risk. Add verification that the url is ours
 */
export const getPointSourceItem = async ({
    stacItemId,
    stacCollectionId,
    pointSourceUrl
}: {
    stacItemId: string;
    // TODO: where else is this set to optional?
    stacCollectionId?: string;
    pointSourceUrl?: string;
}) => {
    // Not all collects have point sources (either none detected or failed QA).
    if (!pointSourceUrl) {
        return null;
    }

    // Don't try to fetch from private google cloud buckets
    if (pointSourceUrl.startsWith('gs')) {
        log.error(`Invalid private gs url for ${stacItemId}.  Could not retrieve Point Sources.`);
        return null;
    }

    try {
        const response = await customFetch(pointSourceUrl);
        if (response.status < 200 || response.status >= 400) {
            log.error(`${response.status} received from cloud. Could not retrieve Point Sources`);
            return null;
        }
        const stacResponse = (await response.json()) as StacSearchResponse;
        return { ...stacResponse, id: stacItemId, collection: stacCollectionId };
    } catch (e) {
        log.error(`Error fetching stac items: ${e}. Could not perform search`);
        return null;
    }
};

/**
 * Get data details from a gridded data source at a lat/lng point, along with
 * the properties for the capture that the point belongs to
 * @param lng
 * @param lat
 * @param endDate
 * @param itemId
 * @param product
 * @param platform
 * @returns the data values at that point
 */
export const getGriddedDataAndPropertiesFromStac = async (
    lng: number,
    lat: number,
    itemId: string,
    product: AreaEmissionsProducts = AreaEmissionsProducts.l4,
    platform: Platforms = Platforms.MSAT
): Promise<AreaFluxFeature | null> => {
    const urls = getStacDataSource();

    const collectionId = getCollectionName(product, platform);

    const itemDataResponse = await customFetch(`${urls.targetsUrl}/collections/${collectionId}/items/${itemId}`);
    const itemData: StacFeature = await itemDataResponse.json();

    /**
     * Quickly returns null if the user clicks outside of the bbox of the capture.  Otherwise,
     * an HTTP 500 is returned from TiTiler with the error 'Point is outside dataset bounds'.
     */
    const clickedPointGeometry: Geometry = { coordinates: [lng, lat], type: 'Point' };
    if (!booleanWithin(clickedPointGeometry, itemData)) {
        return null;
    }

    const cogData = await customFetch(
        `${urls.titilerUrl}/collections/${collectionId}/items/${itemId}/point/${lng},${lat}?assets=COG`
    );

    if (cogData.status < 200 || cogData.status >= 400) {
        log.error(`${cogData.status} received from pgSTAC-Titiler.  Could not access COG metadata.`);
        return null;
    }
    const emissionDataAtPoint = await cogData.json();

    return {
        id: itemId,
        type: 'Feature',
        geometry: {
            coordinates: [lng, lat],
            type: 'Point'
        },
        properties: {
            collectionEndTime: itemData.properties?.end_datetime,
            collectionStartTime: itemData.properties?.start_datetime,
            source: platform,
            collectionId: collectionId,
            sceneId: itemData.properties?.basin,
            methane: emissionDataAtPoint.values[0]
        }
    };
};

/**
 * This should run on L4 data only.
 */
export const useGetHistoricalSamplesForTarget = (targetId: string | null): CalendarData[] | null => {
    const historicalSamplesRef = useRef<StacSearchResponse<Geometry, GeoJsonProperties> | null>(null);
    const platform = useSelector(selectPlatform);
    let search;
    if (targetId) {
        const collection = getCollectionName(undefined, platform);
        search = {
            ...getBasicSearch(),
            'filter-lang': FILTER_LANG,
            filter: getTargetIDFilter(targetId),
            collections: [collection]
        };
    }
    // Get all data for the target.
    const data = useDoSTACSearch(search ? search : null);

    if (data?.features) {
        historicalSamplesRef.current = data;
        return data.features.map(captureFeaturesToDateBlocks);
    }
    if (historicalSamplesRef.current?.features) {
        return historicalSamplesRef.current.features.map(captureFeaturesToDateBlocks);
    }
    return [];
};

const captureFeaturesToDateBlocks = (feature: StacFeature) => {
    const captureDate = new Date(feature.properties?.datetime as string);
    const totalKgHr = feature.properties?.net_total;
    const targetColor = totalKgHr ? colorArrayToRgb(getFilledTargetColor(totalKgHr) as Color) : TARGET_GREY_DATA;
    const coordinates = feature.geometry.type === 'Polygon' ? feature.geometry.coordinates[0] : null;
    return {
        date: captureDate,
        color: targetColor,
        id: feature.id,
        coordinates: coordinates!,
        collection: feature.collection
    };
};
