import { ColorRange, ColorSchemes } from '../types';
import {
    brown,
    clay,
    keplerGreen,
    keplerPurple,
    pastelBlue,
    ssec,
    sunset4,
    sunset5,
    sunset11,
    sunset5WithoutVibrantYellow,
    swamp
} from './ranges';

/**
 * Color palettes for maps visualizations, aka color ramps.
 */
export const colorPalettes: {
    [key in ColorSchemes]: ColorRange;
} = {
    brown,
    clay,
    keplerGreen,
    keplerPurple,
    pastelBlue,
    ssec,
    sunset4,
    sunset5,
    sunset11,
    sunset5WithoutVibrantYellow,
    swamp
};
