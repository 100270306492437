/**
 * Build time type for generating static pages for emissions entities.
 */
export type ScorecardEntity = { slug: string; name: string };

/**
 * Const for build time only, not exported.  In the future this list can be fetched from an API at build time.
 */
export const staticScorecardEntities: ScorecardEntity[] = [
    {
        slug: 'roxxon-energy-corporation',
        name: 'Roxxon Energy Corporation'
    },
    {
        slug: 'hexus',
        name: 'Hexus'
    }
];
