import { MethaneDiffuseRangeFilterKey, MethaneDistinctRangeFilterKey } from '../types';
import { COLOR_STOPS } from './colors';

interface HasMin {
    min: number;
}
interface HasMaxExclusive {
    maxExclusive: number;
}
type HasMinAndOrMaxExclusive = (HasMin & HasMaxExclusive) | (HasMin | HasMaxExclusive);

// [50, 200, 400, 500]
export const methaneDiffuseRangeFilterValues: {
    [Property in MethaneDiffuseRangeFilterKey]: HasMinAndOrMaxExclusive;
} = {
    bin1: {
        maxExclusive: COLOR_STOPS[1]
    },
    bin2: {
        min: COLOR_STOPS[1],
        maxExclusive: COLOR_STOPS[2]
    },
    bin3: {
        min: COLOR_STOPS[2],
        maxExclusive: COLOR_STOPS[3]
    },
    bin4: {
        min: COLOR_STOPS[3],
        maxExclusive: COLOR_STOPS[4]
    },
    bin5: {
        min: COLOR_STOPS[4]
    }
} as const;

export const methaneDiffuseRangeFilterKeys: MethaneDiffuseRangeFilterKey[] = Object.keys(
    methaneDiffuseRangeFilterValues
) as MethaneDiffuseRangeFilterKey[];

export const methanePlumeRangeFilterValues: {
    [Property in MethaneDistinctRangeFilterKey]: HasMinAndOrMaxExclusive;
} = {
    bin1: {
        maxExclusive: 1200
    },
    bin2: {
        min: 1200,
        maxExclusive: 2000
    },
    bin3: {
        min: 2000
    }
} as const;

export const methanePlumeRangeFilterKeys: MethaneDiffuseRangeFilterKey[] = Object.keys(
    methanePlumeRangeFilterValues
) as MethaneDiffuseRangeFilterKey[];

export const MAX_FILTER_CHIPS = 6;
