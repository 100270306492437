import GeoJSON from 'geojson';

import { MultiMVTSubLayerTile, PickingInfo } from '@methanesat/maps';

import { isGeoJsonFeature, isPointFeature } from './Geojson';
import {
    AreaFluxGeoJSONProperties,
    BasinFeatureProperties,
    CaptureFeatureProperties,
    ClusteredOGITileFeatureProperties,
    InfrastructureGeoJSONProperties,
    isAreaFluxProperties,
    isCaptureProperties,
    isClusteredPointOGITileFeature,
    isOGITileFeature,
    isPlumeFluxProperties,
    MethaneLayerIds,
    OGILayerIds,
    OGIPointInfrastructureIds,
    PipelineFeatureProperties,
    PlumeFluxGeoJSONProperties,
    PointOGIMGeoJSONProperties,
    TargetFeatureProperties
} from './MapLayers';
import { MapStateLayerNames } from './MapState';
import { MapControlAccordionProps } from '../components/MapControls/MapControlAccordion';

export interface PickingInfoSubset<
    G extends GeoJSON.Geometry = GeoJSON.Geometry,
    P extends GeoJSON.GeoJsonProperties = GeoJSON.GeoJsonProperties
> {
    layer: { id: MapStateLayerNames };
    object: { id: string } & GeoJSON.Feature<G, P>;
    tile?: MultiMVTSubLayerTile;
}
/** MSatPickInfo['object'] is a feature with geometry of type G and properties of type P */
export type MSatPickInfo<
    G extends GeoJSON.Geometry = GeoJSON.Geometry,
    P extends GeoJSON.GeoJsonProperties = GeoJSON.GeoJsonProperties
> = PickingInfoSubset<G, P> & Pick<PickingInfo, 'viewport' | 'x' | 'y' | 'pixel' | 'coordinate' | 'layer'>;

export interface PickInfoToPrioritize<D = MSatPickInfo['object']> {
    bitmap?: BitmapInfo;
    layer: MSatPickInfo['layer'];
    object: D;
    x: number;
    y: number;
    devicePixel: number[];
    index: number;
    coordinate: number[];
}

interface LayerDataDrawerProps<P, G = GeoJSON.Geometry> {
    info: Omit<MSatPickInfo, 'object'> & {
        object: {
            id: string;
            properties: P;
            geometry: G;
            collection?: string;
            itemId?: string;
        };
    };
    accordion?: boolean;
    AccordionProps?: MapControlAccordionProps;
}
export type PlumeDrawerProps = LayerDataDrawerProps<PlumeFluxGeoJSONProperties, GeoJSON.Point>;

export type TargetDrawerProps = LayerDataDrawerProps<TargetFeatureProperties>;

export type PointInfraDrawerProps = LayerDataDrawerProps<PointOGIMGeoJSONProperties>;

export type PipelineInfraDrawerProps = LayerDataDrawerProps<PipelineFeatureProperties>;

export type BasinDrawerProps = LayerDataDrawerProps<BasinFeatureProperties>;

export type AreaEmissionDrawerProps = LayerDataDrawerProps<AreaFluxGeoJSONProperties>;

export interface BitmapInfo {
    pixel: number[];
    uv: number[];
    size: {
        width: number;
        height: number;
    };
    coordinate: number[];
    layerId: string;
}

export function isBitmapInfo(data: unknown): data is BitmapInfo {
    if (!data) return false;
    if (typeof data !== 'object') return false;
    return 'pixel' in data && 'uv' in data && 'size' in data;
}

export function isPlumeEmissionInfo(
    info: MSatPickInfo
): info is MSatPickInfo<GeoJSON.Point, PlumeFluxGeoJSONProperties> {
    return (
        isGeoJsonFeature(info.object) &&
        isPlumeFluxProperties(info.object.properties) &&
        info.layer.id.includes(MethaneLayerIds.plumeEmissionRate)
    );
}

export function isAreaEmissionInfo(
    info: MSatPickInfo
): info is MSatPickInfo<GeoJSON.Geometry, AreaFluxGeoJSONProperties> {
    return (
        isGeoJsonFeature(info.object) &&
        isAreaFluxProperties(info.object.properties) &&
        info.layer.id.includes(MethaneLayerIds.areaEmissionRaster)
    );
}

/**
 * For pipeline drawers, object must have correct `properties`
 * and `layer.id` must be OGILayerIds.pipelines
 */
export function isPipelineInfo(info: MSatPickInfo): info is MSatPickInfo<GeoJSON.Geometry, PipelineFeatureProperties> {
    return isOGITileFeature(info.object) && info.object.properties.layerName === OGILayerIds.pipelines;
}

/**
 * For target drawers, object must have correct `properties`
 * and `layer.id` must be MethaneLayerIds.targets
 */
export function isTargetInfo(
    info: MSatPickInfo
): info is MSatPickInfo<GeoJSON.Geometry, TargetFeatureProperties | CaptureFeatureProperties> {
    return isCaptureProperties(info.object.properties) && info.layer.id.includes(MethaneLayerIds.targets);
}

/**
 * For point infrastructure drawers, object must have  point geometry, correct `properties`
 * and `properties.layerName` included in point OGIPointInfrastructureIds
 */
export function isPointInfrastructureInfo(
    info: MSatPickInfo
): info is MSatPickInfo<GeoJSON.Point, InfrastructureGeoJSONProperties> {
    return (
        isPointFeature(info.object) &&
        isOGITileFeature(info.object) &&
        Object.values(OGIPointInfrastructureIds).includes(
            info.object.properties.layerName as unknown as OGIPointInfrastructureIds
        )
    );
}

/**
 * Feature must pass checks for `isPointInfrastructureInfo` and include
 * `clustered` and `point_count` properties.
 */
export function isClusteredPointInfrastructureInfo(
    info: MSatPickInfo
): info is MSatPickInfo<GeoJSON.Point, ClusteredOGITileFeatureProperties> {
    return isPointInfrastructureInfo(info) && isClusteredPointOGITileFeature(info.object);
}

/**
 * For basin drawers, object must have correct `properties`
 * and `properties.layerName` of `OGILayerIds.basins`
 */
export function isBasinInfo(info: MSatPickInfo): info is MSatPickInfo<GeoJSON.Geometry, BasinFeatureProperties> {
    return isOGITileFeature(info.object) && info.object.properties.layerName === OGILayerIds.basins;
}

/**
 *
 * Allows us to get the first word in multi-word strings from OGI data fields so we can use
 * that word as a key to get the data from locales file.
 */
export function cleanOgiData(ogiData: string, charToSplit: string) {
    return ogiData.split(charToSplit)[0];
}
