import { useMemo } from 'react';
import { useErrorBoundary as useReactErrorBoundary } from 'react-error-boundary';

import { log } from '@methanesat/log';

export { ErrorBoundary } from 'react-error-boundary';

export const useErrorBoundary = () => {
    const { resetBoundary: resetErrorBoundary, showBoundary: showErrorBoundary } = useReactErrorBoundary();

    // Memoizing as useReactErrorBoundary memoizes its output as well
    return useMemo(
        () => ({
            resetErrorBoundary,
            showErrorBoundary
        }),
        [resetErrorBoundary, showErrorBoundary]
    );
};

/**
 * logs an error with the supplied optional message and the original error's message
 */
export const handleErrorBoundaryError = (message?: string) => {
    return (error: Error) => {
        log.error(`${message && message + '\n'}${error.message}`);
    };
};

// export default { ErrorBoundary, useErrorBoundary };
