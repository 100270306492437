import { DateRanges } from '../hooks';
import { Platforms, STACCollection, SortDirection, StacFilter, StacFormattedBbox, StacSearch } from '../types';
import { getMonthEnd, getMonthStart, getQuarterEnd, getQuarterStart } from './time';

/**
 * Returns the Stac Filter property for a stac search
 * @param targetId
 * @returns Stac Filter
 */
export const getTargetIDFilter = (targetId: string): StacFilter => {
    const filter: StacFilter = {
        op: '=',
        args: [
            {
                property: 'target_id'
            },
            targetId
        ]
    };
    return filter;
};

export const FILTER_LANG = 'cql2-json';

export const formatBbox = (bbox: number[][]): StacFormattedBbox => {
    const corner1 = bbox[0];
    const corner2 = bbox[2];

    const maxLng = Math.max(corner1[0], corner2[0]);
    const maxLat = Math.max(corner1[1], corner2[1]);
    const minLng = Math.min(corner1[0], corner2[0]);
    const minLat = Math.min(corner1[1], corner2[1]);

    return [minLng, minLat, maxLng, maxLat];
};

/**
 * Get a 24hr day range
 * @param endDate
 * @returns
 */
export const getSingleDayRangeString = (endDate: Date) => {
    const start = new Date(endDate.getTime());
    // set time to midnight
    start.setUTCHours(0, 0, 0, 0);
    const end = new Date(start.getTime());
    end.setUTCDate(endDate.getUTCDate() + 1);

    const startString = start.toISOString();
    const endString = end.toISOString();
    return `${startString}/${endString}`;
};

export const getMonthEndString = (inputDate: Date) => {
    const monthEnd = getMonthEnd(inputDate);
    return monthEnd.toISOString();
};

/**
 * Get a datetime range string for a single calendar month
 * For STAC searches
 * Returns a range of one month based on the UTC date of @date
 *
 * Example:
 * `getDatetimeOneMonthRangeString(new Date('Feb 14 2023'))`
 * returns string for Feb 1 - Feb 28 2023:
 * `"2023-02-01T00:00:00.000Z/2023-02-28T23:59:59.999Z"`
 */
export const getDatetimeOneMonthRangeString = (date: Date): string => {
    const startDate = getMonthStart(date);
    const startString = startDate.toISOString();

    const endDate = getMonthEnd(date);
    const endString = endDate.toISOString();

    return `${startString}/${endString}`;
};

export const getDatetimeQuarterlyRangeString = (date: Date): string => {
    const startDate = getQuarterStart(date);
    const startString = startDate.toISOString();

    const endDate = getQuarterEnd(date);
    const endString = endDate.toISOString();

    return `${startString}/${endString}`;
};

export const getDateRangeFromPlatform = (platform: Platforms) => {
    return platform === Platforms.MSAT ? DateRanges.Quarter : DateRanges.Month;
};

/**
 * Default Search parameters
 */
export const BASIC_SEARCH: StacSearch = {
    collections: [STACCollection.MethaneAIR_Level4, STACCollection.MethaneSAT_Level4],
    limit: 100,
    fields: {
        include: ['properties', 'collection', 'links', 'geometry', 'assets'],
        exclude: [
            'properties.proj:epsg',
            'properties.proj:wkt2',
            'properties.proj:transform',
            'properties.proj:bbox',
            'properties.proj:shape'
        ]
    },
    sortby: [
        {
            field: 'properties.end_datetime',
            direction: SortDirection.Decending
        }
    ]
};
