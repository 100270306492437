import { envToReasonablySizedString } from './utils';

/**
 * Reads the Git ref, validates the length arbitrarily and that it starts
 * with the correct prefix. The prefix is defined in GITHUB_REF:
 * https://docs.github.com/en/actions/learn-github-actions/environment-variables#default-environment-variables
 *
 * @example
 * getGitRef()
 * // returns 'refs/tags/v1.0.0'
 */
const getGitRef = () => {
    const ref = envToReasonablySizedString(process.env.NEXT_PUBLIC_GIT_REF);
    const validPrefixes = ['refs/heads', 'refs/pull', 'refs/tags'];

    return ref && validPrefixes.some((prefix) => ref.startsWith(prefix)) ? ref : undefined;
};

export const GIT_REF = getGitRef();

/**
 * Returns the Git branch name, to be used for outputting to an HTML tag for debugging purposes.
 *
 * @example
 * getGitBranch()
 * // returns 'DP-123/my-branch-name'
 */
const getGitBranch = () => {
    const ref = getGitRef();
    if (!ref) return ref;
    const [, branch] = ref.split(/refs\/\w+\//);
    return branch;
};

export const GIT_BRANCH = getGitBranch();

// The length of a git commit hash
// https://git-scm.com/book/en/v2/Git-Tools-Revision-Selection
const COMMIT_HASH_LENGTH = 40;

/**
 * Reads the Git hash, assuming it exists and is the commit hash length.
 *
 * @example
 * getGitHash()
 * // returns 'f3468z'
 */
const getGitHash = () => {
    const rawHash = process.env.NEXT_PUBLIC_GIT_HASH;
    return rawHash && rawHash.length <= COMMIT_HASH_LENGTH ? rawHash : undefined;
};

export const GIT_HASH = getGitHash();

/**
 * Reads the GitHub run number and returns it. The result is not
 * guaranteed to be safe to use as a number type. It is safe
 * to use as a string only.
 *
 * @example
 * getGitHubRunNumber()
 * // returns '4828864413'
 */
const getGitHubRunNumber = () => {
    const rawRunNumber = process.env.NEXT_PUBLIC_GITHUB_RUN_NUMBER;

    return rawRunNumber && /^[0-9]+$/.test(rawRunNumber) ? rawRunNumber : undefined;
};

export const GITHUB_RUN_NUMBER = getGitHubRunNumber();
