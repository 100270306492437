import { ReactNode } from 'react';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';

/**
 * A utility to render a React component to a string. This is useful
 * for tools, such as some Deck.gl layers, that take HTML as an input
 * to allow taking a React component as input. Note that the root is
 * not wrapped by wrappers like Redux or the Material Theme, so accessing
 * these values will produce inconsistent results.
 *
 * Note that React doesn't like when createRoot or flushSync is called
 * from a hook.
 *
 * https://react.dev/reference/react-dom/server/renderToString#removing-rendertostring-from-the-client-code
 */
export const componentToString = (component: ReactNode) => {
    // Create a div component in which the component will be rendered into
    const div = document.createElement('div');
    // Make the div component a React root
    const root = createRoot(div);
    // The flushSync call is necessary so that the DOM is
    // updated before reading its innerHTML property.
    flushSync(() => {
        // Render the component within the React root
        root.render(component);
    });
    // Return the inner HTML of the div component
    return div.innerHTML;
};
