import { ColorRange } from './types';

import { colorPalettes } from '@methanesat/colors';

import { colorAggregationTypes, colorScaleTypes, heatmapColorAggregationTypes } from './types';

/**
 * List of base tile map styles.
 */
export const MapStyles = {
    dark: 'cl8njrpdr000c14pbsub1zphg',
    satellite: 'cl58evdic001315peb5pc7xyl',
    satelliteStreets: 'cl3dbxu32000714qoch59omp8',
    satelliteMuted: 'cl8swah7y003p16s89wzwxkj7'
} as const;

export const mapboxBaseStylePath = 'mapbox://styles/methanesat';

// Max zoom level for heatmap calculations
export const MAX_ZOOM = 22;

/** Degault settings for MVT layer */
const mvt = {
    binary: false,
    tileSize: 1024
};

/** Default settings for the Heatmap layer */
const heatmap = {
    aggregation: heatmapColorAggregationTypes.sum,
    intensity: 1,
    radiusPixels: 30,
    threshold: 0.05,
    debounceTimeout: 400,
    weightsTextureSize: 2,
    colorDomain: [0.05, 800]
};

/** Default settings for the Hexagon layer */
const hexagon = {
    colorAggregation: colorAggregationTypes.sum,
    elevationRange: [0, 3000] as [number, number],
    colorScaleType: colorScaleTypes.quantile,
    elevationScale: 50,
    radius: 6000
};

/** Default settings for aggregation layers in general */
const aggregationLayer: { colorRange: ColorRange } = {
    colorRange: colorPalettes.sunset11
} as const;

/** Default settings for map layers in general */
const layer = {
    opacity: 1,
    pickable: false
} as const;

export const MAX_LATIUDE = 90;
export const MAX_LONGITUDE = 180;
export const MIN_LATITUDE = -90;
export const MIN_LONGITUDE = -180;

/** Default settings for the BaseMap component - deck.gl & static map settings */
const basemap = {
    mapStyle: `${mapboxBaseStylePath}${MapStyles.dark}`,
    initialViewState: {
        altitude: 1.5,
        bearing: 0,
        latitude: 0,
        longitude: 0,
        minZoom: 2,
        maxZoom: 15,
        pitch: 0,
        zoom: 0
    }
};

/**
 * Default map settings.
 */
export const defaults = {
    aggregationLayer,
    basemap,
    heatmap,
    hexagon,
    layer,
    mvt
};
