import { useState, useEffect } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MarkdownFormatter
} from '@methanesat/ui-components';

import { useTranslate } from '../../hooks';
import { analytics } from '../../utils';
import { Notifier } from '../../utils/Notifier';

/**
 * Displays a message to the user that they need to refresh their page in order
 * to keep using the app because of restrictions with the Identity Platform on
 * Google cloud.
 */
const RefreshDialog = () => {
    const [isRefreshDialogDisplayed, setIsRefreshDialogDisplayed] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const t = useTranslate();

    useEffect(() => {
        const notifier = new Notifier();
        notifier.subscribeOnce(() => {
            setIsRefreshDialogDisplayed(true);
            analytics.viewRefreshDialog();
        });
    }, []);

    /**
     * Navigating to the same location when used with Identity Platform
     * will allow re-authorization to happen and return the user to the state
     * they were previously at before refreshing the page.
     */
    const handleRefresh = () => {
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href;
        // This prevents the user from clicking refresh multiple times.
        // Since we are refreshing the whole page, the state will be reset, and
        // we don't need code that sets isDisabled to false.
        setIsDisabled(true);
    };

    return (
        <Dialog
            open={isRefreshDialogDisplayed}
            aria-labelledby="refresh-dialog-title"
            aria-describedby="refresh-dialog-description"
        >
            <DialogTitle id="refresh-dialog-title">{t('refreshDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="refresh-dialog-description">
                    <MarkdownFormatter markdown={t('refreshDialog.body')} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={isDisabled} data-testid="dialog-refresh-button" onClick={handleRefresh} autoFocus>
                    {t('refreshDialog.action')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RefreshDialog;
