import { RootState } from '../../../../store';
import { selectMethaneLayerConfig, selectOGILayerConfig } from './mapLayerSelectors';
import { NothingHighlighted } from '../../../../types';

export const selectFeatureSelected = (state: RootState) => state.pages.emissions.selectedFeature;

// collection id
export const selectVisualizedStacCollectionId = (state: RootState) =>
    selectFeatureSelected(state).selectedMethaneFeature.selectedStacCollectionId;

// item id
export const selectVisualizedStacItemId = (state: RootState) =>
    selectFeatureSelected(state).selectedMethaneFeature.selectedStacItemId;

// feature type
export const selectVisualizedFeatureType = (state: RootState) =>
    selectFeatureSelected(state).selectedMethaneFeature.selectedFeatureType;

export const selectHighlightedObjectIndex = (state: RootState) => {
    const layers = { ...selectMethaneLayerConfig(state), ...selectOGILayerConfig(state) };

    const layerWithHighlight = Object.values(layers).find((layer) => layer.highlightedObject);
    return layerWithHighlight?.highlightedObjectIndex;
};

export const selectHighlightedFeature = (state: RootState) => {
    const layers = { ...selectMethaneLayerConfig(state), ...selectOGILayerConfig(state) };

    const layerWithHighlight = Object.values(layers).find((layer) => layer.highlightedObject);
    return layerWithHighlight?.highlightedObject || null;
};

export const selectHighlightedLayerId = (state: RootState) => {
    const layers = { ...selectMethaneLayerConfig(state), ...selectOGILayerConfig(state) };
    const layerWithHighlight = Object.entries(layers).find(([_key, layer]) => !!layer.highlightedObject);
    return layerWithHighlight?.[0] || null;
};

export const selectCoordinateFromHighlightedFeature = (state: RootState) => {
    let toReturn: number[] | undefined;
    const info = selectHighlightedFeature(state);
    const highlightedObjectIndex = selectHighlightedObjectIndex(state);
    if (info && highlightedObjectIndex !== NothingHighlighted.highlightedObjectIndex && 'coordinate' in info) {
        toReturn = info.coordinate;
    }

    return toReturn;
};
