import { createAction } from '@reduxjs/toolkit';
import {
    HighlightedObject,
    MapStateLayerNames,
    PipelineFeatureProperties,
    PointOGIMGeoJSONProperties
} from '../../../types';

/** This file contains actions that will be handled by multiple slices to update their respective parts of the state. */

export const setOgiNearPlumes = createAction<{
    longitude: number;
    latitude: number;
    ogimFeatures: PipelineFeatureProperties[] | PointOGIMGeoJSONProperties[];
}>('emissions/setOgiNearPlumes');

/**
 * Undoes the set action for the ogi near plumes feature
 */
export const resetOgiNearPlumes = createAction('emissions/resetOgiNearPlumes');

/** Zoom to locations */
export const zoomToPermianAreaEmissions = createAction('emissions/zoomToPermianAreaEmissions');

export const zoomToPermianPlumeEmissions = createAction('emissions/zoomToPermianPlumeEmissions');

export const zoomToPermianTarget = createAction('emissions/zoomToPermianTarget');

export const zoomToPermianTargetBroad = createAction('emissions/zoomToPermianTargetBroad');

export const zoomToPermianOilAndGas = createAction<{ filterExpanded: boolean }>('emissions/zoomToPermianOilAndGas');

/** Highlight related actions */
export const addOneMapFeatureHighlight = createAction<{
    index?: number;
    layerId: MapStateLayerNames;
    objectId: string | null;
    object: HighlightedObject;
    coordinate?: number[];
}>('emissions/addOneMapFeatureHighlight');

export const addMultipleMapFeatureHighlights = createAction<{
    layerId: MapStateLayerNames;
    features: { index: number; objectId: string | null }[];
}>('emissions/addMultipleMapFeatureHighlights');

export const removeAllMapFeatureHighlights = createAction('emissions/removeAllMapFeatureHighlights');
