import { GOOGLE_MAPS_API_KEY } from './googleCloudServices';
import { INFRASTRUCTURE_API_BASE_URL, INFRASTRUCTURE_DYNAMIC_TILE_URL, INFRASTRUCTURE_STATIC_TILE_URL } from './layers';
import { MAPBOX_TOKEN } from './mapbox';
import { envToBoolean, getUndefinedRequired } from './utils';

export const USE_GLOBAL_DATE_PICKER = envToBoolean(process.env.NEXT_PUBLIC_USE_GLOBAL_DATE_PICKER);
export const SHOW_TARGET_LABELS_ON_MAP = envToBoolean(process.env.NEXT_PUBLIC_SHOW_TARGET_LABELS_ON_MAP);

export const INTERNAL_ONLY_SIGNAGE = envToBoolean(process.env.NEXT_PUBLIC_INTERNAL_ONLY_SIGNAGE);

/**
 * Ensures that required env variables are set for the Emissions Map.
 */
export const checkEmissionsMapEnv = () => {
    const hasMapsKey = GOOGLE_MAPS_API_KEY || MAPBOX_TOKEN;
    if (!hasMapsKey) {
        throw new Error(
            `Misconfiguration detected.  Either NEXT_PUBLIC_GOOGLE_MAPS_API_KEY or NEXT_PUBLIC_MAPBOX_TOKEN token must be provided, but found none.`
        );
    }

    const requiredEnvVariables = {
        INFRASTRUCTURE_API_BASE_URL,
        INFRASTRUCTURE_DYNAMIC_TILE_URL,
        INFRASTRUCTURE_STATIC_TILE_URL
    };

    const prefixWithNextPublic = (str: string) => `NEXT_PUBLIC_${str}`;
    const missingRequired = getUndefinedRequired(requiredEnvVariables).map(prefixWithNextPublic);
    if (missingRequired.length > 0) {
        throw new Error(`Misconfiguration detected.  Missing env variables: ${missingRequired.join(', ')}
Please review the readme: https://github.com/methanesat-org/msat-ui/tree/main/packages/app#readme`);
    }
};
