/**
 * Constants related to specific data sources.
 */

import { MethaneLayerIds } from '../types';
import { EMISSION_DB_URL, INFRASTRUCTURE_API_BASE_URL, TITILER_URL } from '../environmentVariables';

export const OPERATOR_NAMES_URL = `${INFRASTRUCTURE_API_BASE_URL}/rpc/visible_bounded_operator_list`;
export const INFRASTRUCTURE_DATA_VERSION_URL = `${INFRASTRUCTURE_API_BASE_URL}/database_current_state`;

/**
 * Link to paper via EDF Science (May 2024).
 * See https://methanesat.atlassian.net/browse/DP-3023?focusedCommentId=20452
 */
export const OGIM_DATA_SOURCE_URL = 'https://zenodo.org/records/13259749';

export const METHANESAT_FAQ_URL = 'https://www.methanesat.org/web-portal-questions';

/** map of data sources */
export const dataSources = {
    [MethaneLayerIds.areaEmissionRaster]: {
        url: {
            titilerUrl: TITILER_URL,
            targetsUrl: EMISSION_DB_URL
        }
    }
} as const;
