import { useCallback } from 'react';

import { useLocale } from './internationalization';

/**
 * Custom hook that prefixes the current locale in front of a URL path.
 * @example
 * const generateLocaleRoute = useLocaleRoute();
 * generateLocaleRoute('/foo')
 * // -> '/en/foo'
 */
export const useLocaleRoute = (): ((route?: string) => string) => {
    const locale = useLocale();

    return useCallback(
        (route?: string): string => {
            let localeRoute = `/${locale}`;

            if (route) {
                if (route[0] !== '/') {
                    localeRoute = `${localeRoute.slice(1)}/${route}`;
                } else {
                    localeRoute = `${localeRoute}${route}`;
                }
            }

            return localeRoute;
        },
        [locale]
    );
};
