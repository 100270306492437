import { recomposeColor, decomposeColor, ColorObject } from '@mui/material/styles';
import { scaleLinear } from 'd3-scale';
import { ColorDomain, Color, RGB, RGBA } from '../types';
import { hexToRgb, rgbToHex } from '@mui/material/styles';

export * from './picking';

/** Value extremes for CSS alpha opacity in [min, max] format. */
const cssAlphaRange: ColorDomain = [0, 1];

/** Value extremes for deck.gl alpha opacity in [min, max] format. */
const deckAlphaRange: ColorDomain = [0, 255];

/**
 * Converts CSS-style alpha opacity (0-1) to deck.gl-style alpha opacity (range 0-255).
 * @example
 * cssAlphaToDeckAlpha(0.5)
 * // -> 127
 */
const cssAlphaToDeckAlpha = (alpha: number | undefined) => {
    if (typeof alpha === 'undefined') {
        return deckAlphaRange[1]; // defaults to fully visible
    }

    const scale = scaleLinear().domain(cssAlphaRange).range(deckAlphaRange);

    return Math.floor(scale(alpha));
};

/**
 * Converts deck.gl-style alpha opacity (range 0-255) to CSS-style alpha opacity (0-1).
 * @example
 * deckAlphaToCSSAlpha(127)
 * // -> 0.5
 */
const deckAlphaToCSSAlpha = (alpha: number | undefined) => {
    if (typeof alpha === 'undefined') {
        return cssAlphaRange[1]; // defaults to visible
    }

    const scale = scaleLinear().domain(deckAlphaRange).range(cssAlphaRange);

    return parseFloat(scale(alpha).toFixed(2));
};

/**
 * Converts a CSS rgb/a string into a deck.gl array color array.
 * @example
 * rgbToColorArray('rgb(200, 100, 50)')
 * // -> [200, 100, 50]
 * rgbToColorArray('rgba(200, 100, 50, 0.5)')
 * // -> [200, 100, 50, 127]
 */
export const rgbToColorArray = (color: string): Color => {
    const [red, green, blue, alpha] = decomposeColor(color).values;

    return typeof alpha !== 'undefined' ? [red, green, blue, cssAlphaToDeckAlpha(alpha)] : [red, green, blue];
};

/**
 * Converts a deck.gl array color array into a CSS rgb/a string.
 * @example
 * colorArrayToRgb([200, 100, 50])
 * // -> 'rgb(200, 100, 50)'
 * colorArrayToRgb([200, 100, 50, 127])
 * // -> 'rgba(200, 100, 50, 0.5)'
 */
export const colorArrayToRgb = (color: Color) => {
    const isRgba = color.length === 4;
    const [red, green, blue, alpha] = color;

    const colorObject = isRgba
        ? { type: 'rgba', values: [red, green, blue, deckAlphaToCSSAlpha(alpha)], colorSpace: undefined }
        : { type: 'rgb', values: color, colorSpace: undefined };

    return recomposeColor(colorObject as ColorObject);
};

/**
 * Given a background color, converts a RGBA color to a RGB color.
 * Source: https://stackoverflow.com/a/21576659
 * @example
 * rgbaToRgb([10, 10, 10, 50], [255, 255, 255])
 * // returns [206, 206, 206]
 */
export const rgbaToRgb = (rgba: RGBA, background: RGB): RGB => {
    const [red, green, blue, deckAlpha] = rgba;
    const [backgroundRed, backgroundGreen, backgroundBlue] = background;

    const alpha = deckAlphaToCSSAlpha(deckAlpha);

    return [
        (1 - alpha) * backgroundRed + alpha * red,
        (1 - alpha) * backgroundGreen + alpha * green,
        (1 - alpha) * backgroundBlue + alpha * blue
    ];
};

/**
 * Converts color as deck.gl formatted color array to a hex string
 * @param color as color array
 * @returns color as hex string
 */
export const colorArrayToHex = (color: Color) => {
    const rgba = colorArrayToRgb(color as Color);
    return rgbToHex(rgba);
};

/**
 * Converts a hex string to a color array
 * @param hex string for color
 * @returns color as a Color Array
 */
export const hexToColorArray = (color: string) => {
    const rgba = hexToRgb(color);
    return rgbToColorArray(rgba);
};

/**
 * Sets the alpha of a deck.gl color array. Adds or replaces the alpha value as needed.
 */
export const setDeckGLAlpha = (color: Color, alpha: number): RGBA => {
    if (color.length === 3) return [...color, alpha];
    color[3] = alpha;
    return color;
};
