export const MAX_COG_OPACITY = 0.7;

export const DEFAULT_COG_OPACITY = MAX_COG_OPACITY;

/**
 * Zoom thresholds to create gradual opacity smoothing.
 * Note that deck.gl layer transitions would be ideally used instead here, but the behavior
 * seems wonky (transition works as expected when zooming in but not zooming out).  More info:
 * https://deck.gl/docs/api-reference/core/layer#transitions
 */
export const cogZoomOpacityThresholds = [
    {
        minExclusive: 9,
        maxInclusive: 10,
        opacity: MAX_COG_OPACITY - 0.1
    },
    {
        minExclusive: 10,
        maxInclusive: 11,
        opacity: MAX_COG_OPACITY - 0.2
    },
    {
        minExclusive: 11,
        maxInclusive: 12,
        opacity: MAX_COG_OPACITY - 0.3
    },
    {
        minExclusive: 12,
        maxInclusive: 19,
        opacity: MAX_COG_OPACITY - 0.5
    }
];

/**
 * Opacity as a function of zoom (0-19, where 0 is zoomed out to the entire world).
 * - makes area emissions very prominent when zoomed out, when a user is unlikely to need to see underlying
 * layers.
 * - makes area emissions more transparent when zoomed in, when a user is likely to need to see underlying
 * layers.
 */
export const getCOGOpacity = (zoom: number) => {
    const { opacity } =
        cogZoomOpacityThresholds.find(({ minExclusive, maxInclusive }) => {
            return zoom > minExclusive && zoom <= maxInclusive;
        }) || {};

    return typeof opacity === 'number' ? opacity : DEFAULT_COG_OPACITY;
};
