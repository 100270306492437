import { Kilograms, KilogramsPerHour, MetricTonnes } from '../types';

export const KILOGRAMS_IN_ONE_METRIC_TONNE: Kilograms = 1_000;
export const FORMAT_EMISSIONS_ONE_SIG_FIG_CUTOFF_KG_HR = 20;

/**
 * Formats a number according to a user's locale preferences.  Defaults to current locale when not
 * explicitly passed in.
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 * @example
 * formatNumber(1000);
 * // -> '1,000'
 * formatNumber(1000, 'de-DE');
 * // -> '1.000'
 */
export const formatNumber = (num: number, locale: string | undefined = undefined) =>
    new Intl.NumberFormat(locale).format(num);

/**
 * Converts kilograms to metric tonnes.  Note: different than non-metric tons.
 * See https://en.wikipedia.org/wiki/Tonne
 */
export const kgToTonnes = (kg: Kilograms, round = true): MetricTonnes => {
    const result = kg / KILOGRAMS_IN_ONE_METRIC_TONNE;

    return round ? Math.round(result) : result;
};

/**
 * Converts metric tonnes to kilograms.  Note: different than non-metric tons.
 * See https://en.wikipedia.org/wiki/Tonne
 */
export const tonnesToKg = (tonnes: MetricTonnes): Kilograms => tonnes * KILOGRAMS_IN_ONE_METRIC_TONNE;

/**
 * Rounds a value for display using significant digits.  Generally we store numbers at a higher precision to allow us to
 * perform simple clientside calculations while keeping rounding errors to a minimum.  However the precision needed
 * for these calculations is higher than the precision we want to display to the user, which is why we need this helper
 * function.  For human readability, the least significant digits are both distracting and not always necessary to
 * display at full precision.
 * @example
 * roundUsingSigFigs(7800);
 * // -> '8,000'
 * roundUsingSigFigs(155000);
 * // -> '160,000'
 */
export const roundUsingSigFigs = (val: number, units = '') => {
    const roundVal = Math.round(val);
    const strLength = `${roundVal}`.length;
    const maximumSignificantDigits = strLength < 5 ? 1 : strLength - 3;
    const result = val.toLocaleString(undefined, { maximumSignificantDigits });
    return `${result}${units ? ` ${units}` : ''}`;
};

/**
 * Formats emissions for display (e.g. `1.2345` displayed as `1`, `239.56158` displayed as `240`).
 * Precision displayed implies confidence, e.g. if we display "1.2" this implies we know the value isn't "1.3", which
 * itself implies our confidence is within ~8% (0.1 / 1.2 ~= 0.08 ~= 8%).  This is misleading especially for lower
 * values which have a much wider range of confidence (>100%).
 *
 * Currently the logic is very simple (if below a certain cutoff, show with 1 sig fig, otherwise show with 2). This
 * works decently, though if there's a need in the future we might consider tying significant digits to confidence
 * interval.
 */
export const formatEmissions = (kgHr: KilogramsPerHour) => {
    const maximumSignificantDigits = kgHr <= FORMAT_EMISSIONS_ONE_SIG_FIG_CUTOFF_KG_HR ? 1 : 2;
    return kgHr.toLocaleString(undefined, { maximumSignificantDigits });
};

/**
 * Formats emissions in a minified format.
 * @example
 * formatTotalEmissionsMinified(100_000);
 * -> '100K'
 */
export const formatTotalEmissionsMinified = (kgHr: KilogramsPerHour) => {
    const formatter = Intl.NumberFormat(undefined, { notation: 'compact' });
    return formatter.format(kgHr);
};

/**
 * Calculates and formats a percentage for display.
 * @example
 * formatPercentage(2, 3);
 * // -> 66
 */
export const formatPercentage = (numerator: number, denominator: number) => Math.round((numerator / denominator) * 100);

/**
 * Helper for checking if an input is a number using a type guard that returns a type predicate.
 * See https://www.typescriptlang.org/docs/handbook/advanced-types.html#using-type-predicates
 */
export const isNumber = (maybeNumber: unknown): maybeNumber is number => Number.isFinite(maybeNumber);

/**
 * Formats infrastructure counts to fit attractively within an infrastructure cluster.
 * An infrastructure count of 1420 becomes 1.4k
 */
export const cleanInfraCount = (count: number) => {
    let roundedCount;
    if (count >= 1000) {
        roundedCount = count / 1000;
        if (roundedCount < 10 && roundedCount % 1 != 0) {
            const formattedNumber = parseFloat(formatNumber(roundedCount));
            return formattedNumber.toFixed(1).concat('k');
        }

        const formattedNumber = parseInt(formatNumber(roundedCount));
        return formattedNumber.toFixed().concat('k');
    }
    return formatNumber(count);
};
