import { ThemeMode } from '@methanesat/ui-components';

/**
 * Returns the theme mode if it is set and is a valid mode.
 *
 * @example
 * getThemeMode()
 * // returns 'light'
 */
const getThemeMode = () => {
    const lowerCaseRawTheme = process.env.NEXT_PUBLIC_THEME_MODE?.toLowerCase();

    return lowerCaseRawTheme && Object.values<string>(ThemeMode).includes(lowerCaseRawTheme)
        ? (lowerCaseRawTheme as ThemeMode)
        : undefined;
};

export const THEME_MODE = getThemeMode();
