import { GetStaticPathsResult } from 'next';
import { ClassAttributes, HTMLAttributes } from 'react';

export * from './Attribute';
export * from './DeepPartial';
export * from './user';
export * from './fetch';
export * from './Geojson';
export * from './GeoTiff';
export * from './MapControls';
export * from './MapLayers';
export * from './MapModals';
export * from './MapState';
export * from './metric';
export * from './STAC';

/**
 * Meta data used to figure out which dictionary file or files
 * to import for localization.
 */
export interface DictionaryMeta {
    page: string;
}

/**
 * Result of dispatch helper functions, which creates side-effects through dispatch
 * and doesn't return data directly.
 */
export type DispatchHelperReturnType = undefined;

/**
 * Generic entity, either a company or a country.
 */
export type Entity = {
    /** Company name. */
    name: string;
    /** URL path. */
    slug: string;
};

/**
 * Metadata for a single country.
 */
export type Country = Entity;

/**
 * Metadata for a single company.
 */
export type Company = Entity;

/**
 * Next js paths type when returning from getStaticPaths function
 */
export type PathParams = GetStaticPathsResult['paths'];

/** List of scorecard entities. */
export type ScorecardList = { countries: Country[]; companies: Company[] };

export type SupportedLocale = 'en';

export type SupportedLocaleObject = { language: SupportedLocale; dictionary: Record<string, unknown> };

/** Metadata for a single entity's scorecard. */
export type ScorecardData = { name: string; slug: string; type: string; website?: string };

export type MarkdownHeadingProps = Omit<ClassAttributes<HTMLHeadingElement>, 'ref'> &
    HTMLAttributes<HTMLHeadingElement>;

export type MarkdownPProps = Omit<ClassAttributes<HTMLParagraphElement>, 'ref'> & HTMLAttributes<HTMLParagraphElement>;
