/**
 * Types supporting map layer attributes
 */

// Typings to support GeoJSON data
// See https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/geojson/index.d.ts
import GeoJSON, { Feature } from 'geojson';

import { isGeoJsonFeature, isPointFeature } from './Geojson';
import { StacFeature } from './STAC';

export enum AreaEmissionsProducts {
    l3 = 'l3',
    l4 = 'l4'
}
export const areaEmissionsProductValues: (keyof typeof AreaEmissionsProducts)[] = Object.keys(
    AreaEmissionsProducts
) as (keyof typeof AreaEmissionsProducts)[];
export type AreaEmissionsProduct = (typeof areaEmissionsProductValues)[number];

/** All plume layer ids. */
export enum MethaneLayerIds {
    targets = 'targets',
    plumeEmissionRate = 'plume-emission-rate',
    areaEmissionRaster = 'area-emission-raster',
    areaEmissionRasterHighlight = 'highlight-for-area-emissions-raster'
}

export enum UrlFeatureParams {
    Plume = 'point-source',
    AreaEmission = 'ae',
    Target = 'target'
}

export const UrlFeatureToId = new Map<UrlFeatureParams, string>([
    [UrlFeatureParams.Plume, MethaneLayerIds.plumeEmissionRate],
    [UrlFeatureParams.AreaEmission, MethaneLayerIds.areaEmissionRaster],
    [UrlFeatureParams.Target, MethaneLayerIds.targets]
]);

/** All infrastructure ids. */
export enum OGILayerIds {
    basins = 'basins',
    compressorStations = 'compressors',
    equipmentComponents = 'equipment_components',
    // fields = 'fields',
    injectionAndDisposal = 'injection_disposal',
    // licenseBlocks = 'license_blocks',
    lng = 'lng_facilities',
    naturalGasFlaringSites = 'flares',
    offshorePlatforms = 'offshore_platforms',
    petroleumTerminals = 'petroleum_terminals',
    pipelines = 'pipelines',
    pointInfrastructure = 'point_infrastructure',
    processingPlants = 'gathering_processing',
    // productionGrid = 'production_grid',
    refineries = 'refineries',
    stationsOther = 'stations_other',
    tankBatteries = 'tank_batteries',
    tileInfrastructure = 'tile_infrastructure',
    wells = 'wells'
}

export enum OGIPointInfrastructureIds {
    compressorStations = OGILayerIds.compressorStations,
    equipmentComponents = OGILayerIds.equipmentComponents,
    injectionAndDisposal = OGILayerIds.injectionAndDisposal,
    lng = OGILayerIds.lng,
    naturalGasFlaringSites = OGILayerIds.naturalGasFlaringSites,
    offshorePlatforms = OGILayerIds.offshorePlatforms,
    petroleumTerminals = OGILayerIds.petroleumTerminals,
    processingPlants = OGILayerIds.processingPlants,
    refineries = OGILayerIds.refineries,
    stationsOther = OGILayerIds.stationsOther,
    tankBatteries = OGILayerIds.tankBatteries,
    wells = OGILayerIds.wells
}
export const OGILayerIdOrder: OGILayerIds[] = [
    OGILayerIds.pipelines,
    OGILayerIds.wells,
    OGILayerIds.refineries,
    OGILayerIds.processingPlants,
    OGILayerIds.injectionAndDisposal,
    OGILayerIds.compressorStations,
    OGILayerIds.naturalGasFlaringSites,
    OGILayerIds.offshorePlatforms,
    OGILayerIds.petroleumTerminals,
    OGILayerIds.tankBatteries,
    OGILayerIds.stationsOther
] as const;
export interface LoadedData {
    [key: string]: number | string;
}

// types & type guards specific to geojson from l4 diffuse data
export interface AreaFluxGeoJSONProperties {
    basin?: string;
    collectionEndTime: string;
    collectionId: string;
    collectionStartTime: string;
    methane: number;
    sceneId: string;
    source: string;
}
export type AreaFluxFeature = GeoJSON.Feature<GeoJSON.Point, AreaFluxGeoJSONProperties>;
export function isAreaFluxProperties(data: unknown): data is AreaFluxGeoJSONProperties {
    if (!data || typeof data !== 'object') return false;
    return 'methane' in data;
}

// Updated Plume format from STAC
export type PlumeGeoJSONProperties = {
    end_datetime: string;
    flux_hi: number;
    flux_lo: number;
    flux_sd: number;
    flux: number;
    instrument: string;
    location: string;
    start_datetime: string;
};
// types specific to geojson from l4 distinct data
export type PlumeFluxGeoJSONProperties = PlumeGeoJSONProperties;

export type PlumeFluxFeature = GeoJSON.Feature<GeoJSON.Point, PlumeFluxGeoJSONProperties>;
export function isPlumeFluxProperties(data: unknown): data is PlumeFluxGeoJSONProperties {
    if (!data || typeof data !== 'object') return false;
    return ('methane' in data && 'layerName' in data) || 'flux' in data;
}

/**
 * enums and data structures from OGIM DB
 */
type PubPriv = 'public' | 'proprietary';
type SrcType = 'academia' | 'arcgis online' | 'company' | 'data vendor' | 'government' | 'ngo' | 'oginfra.com';
type UpdateFreq = 'annually' | 'daily' | 'irregularly' | 'monthly' | 'other' | 'quarterly' | 'weekly';
type OGIMStatus =
    | 'abandoned'
    | 'completed'
    | 'drilling'
    | 'inactive'
    | 'injecting'
    | 'operational'
    | 'other'
    | 'permitting'
    | 'producing'
    | 'proposed'
    | 'storage, maintenance, or observation'
    | 'under construction';
interface OGISourceData {
    pub_priv: PubPriv;
    src_date: string;
    src_name: string;
    src_id: number;
    src_type: SrcType;
    src_url: string;
    update_freq: UpdateFreq;
}
// types specific to geojson from infrastructure data
interface OGIMGeoJSONProperties {
    ogim_id: number | string;
    layerName: OGILayerIds;
    state_prov: string;
    country: string;
    sources: OGISourceData[];
}

interface Operator {
    operator_id: number;
    operator_name: string;
    ownership_interval: string;
}
export interface InfrastructureGeoJSONProperties extends OGIMGeoJSONProperties {
    fac_id: string | null;
    fac_name: string | null;
    fac_status: string | null;
    fac_type: string | null;
    operators: (Operator | null)[] | null;
    install_date: string | null;
    ogim_status: OGIMStatus | null;
}
// layer-type specific attributes
export interface PipelineFeatureProperties extends InfrastructureGeoJSONProperties {
    details: {
        commodity?: string;
        gas_capacity_mmcfd?: number;
        gas_throughput_mmcfd?: number;
        liq_capacity_bpd?: number;
        liq_throughput_bpd?: number;
        pipe_diameter_mm?: number;
        pipe_length_km?: number;
        pipe_material?: string;
    };
}
export interface PointOGIMGeoJSONProperties extends InfrastructureGeoJSONProperties {
    details: {
        average_flare_temp_k?: number | null;
        commodity?: string | null;
        days_clear_observations?: number | null;
        drill_date?: string | null;
        drill_type?: string | null;
        flare_year?: number | null;
        gas_capacity_mmcfd?: number | null;
        gas_flared_mmcf?: number | null;
        gas_throughput_mmcfd?: number | null;
        liq_capacity_bpd?: number | null;
        liq_throughput_bpd?: number | null;
        num_compr_units?: number | null;
        num_storage_tanks?: number | null;
        segment_type?: string | null;
        site_hp?: number | null;
        spud_date?: string | null;
    };
}

/**
 * END enums & data structures from OGIM DB
 */

export interface BasinFeatureProperties extends OGIMGeoJSONProperties {
    details: {
        area_km2: number;
        name: string;
        reservoir_type: string;
    };
}

export interface TargetTypeProperties {
    /** Unique ID for this specific target. */
    targetId?: string;
    target_id: string;
    /** Collection ID that the target belongs to. */
    collectionId?: string;
    /** GeoJSON coordinates of the target's geometry */
    coordinates?: GeoJSON.Position[];
}
export type TargetTypeFeature = GeoJSON.Feature<GeoJSON.Polygon, TargetTypeProperties>;

export interface TargetFeatureProperties extends TargetTypeProperties {
    /** Instrument used for this measurement. */
    instrument?: string;
    /** Location where the measurement was taken. */
    location?: string;
    /** Total size of the target in km². */
    sizeKm2?: number;
    /** Unique ID for this specific target. */
    targetId?: string;
    /** End of the data capture interval in ISO-8601 UTC format. */
    timeEnd?: string;
    /** Start of the data capture interval in ISO-8601 UTC format. */
    timeStart?: string;
    /** Upper confidence interval in kg/hr. */
    totalHighKgHr?: number;
    /** Total measured emissions of the entire target. */
    totalKgHr?: number;
    /** Lower confidence interval in kg/hr. */
    totalLowKgHr?: number;
    /** Total number of discernable plumes in the target. */
    pointSourceCount?: number;
    /**
     * Sum total kg/hr of all discernable plumes in the target, used to determine their contribution
     * relative to overall area emissions.
     */
    pointSourceTotalKgHr?: number;
}
export type TargetFeature = GeoJSON.Feature<GeoJSON.Polygon, TargetFeatureProperties>;

// updated Capture Type
export interface CaptureFeatureProperties extends TargetTypeProperties {
    /** Unique ID for this specific target. */
    target_id: string;
    /** Basin where capture taken */
    basin?: string;
    /** Descriptive capture title */
    title?: string;
    /** Description */
    description?: string;
    /** States covered by capture */
    states?: string[];
    /** Country locating capture */
    country?: 'string';
    /** Total number of discernable plumes in the capture. */
    ps_count?: number;
    /**
     * Sum total kg/hr of all discernable plumes in the capture, used to determine their contribution
     * relative to overall area emissions.
     */
    ps_total?: number;
    /** Total size of the capture in km². */
    size_km2?: number;
    /** Total measured emissions of the entire capture. */
    net_total?: number;
    /** Total measured emissions of just the area emissions. */
    area_total?: number;
    /** Instrument used for this measurement. */
    instrument?: string;
    /** Unique ID for this specific capture. */
    capture_id?: string;
    /** Upper confidence interval in kg/hr. */
    net_total_high?: number;
    /** Lower confidence interval in kg/hr. */
    net_total_low?: number;
    /** date of capture */
    datetime?: string;
    /** End of the data capture interval in ISO-8601 UTC format. */
    end_datetime?: string;
    /** Start of the data capture interval in ISO-8601 UTC format. */
    start_datetime?: string;
}
export type CaptureFeature = StacFeature & GeoJSON.Feature<GeoJSON.Polygon, CaptureFeatureProperties>;
export type CaptureFeatureCollection = GeoJSON.FeatureCollection<GeoJSON.Polygon, CaptureFeatureProperties>;

export function isCaptureFeature(data: unknown): data is CaptureFeature {
    if (!data || typeof data !== 'object') return false;
    if (Array.isArray(data)) return false;
    return 'geometry' in data && 'properties' in data && isCaptureProperties(data.properties);
}

/**
 * If the proprties are of a Capture
 * @param data
 * @returns boolean if data is a capture
 */
export function isCaptureProperties(data: unknown): data is CaptureFeatureProperties {
    if (!data || typeof data !== 'object') return false;
    return 'target_id' in data;
}

/**
 * types related to tile layer
 */
export type OGITileFeature = { id: string | number } & (
    | PointOGITileFeature
    | LineOGITileFeature
    | PolygonOGITileFeature
);

export function isOGITileFeature(data: unknown): data is OGITileFeature {
    if (!isGeoJsonFeature(data)) return false;
    if (!('properties' in data) || !data.properties) return false;
    return 'layerName' in data.properties;
}
export interface OGITileFeatureProperties {
    layerName: OGILayerIds;
}
export interface ClusteredOGITileFeatureProperties extends OGITileFeatureProperties {
    point_count: number;
    clustered: boolean;
}
export type PointOGITileFeature = Required<GeoJSON.Feature<GeoJSON.Point, OGITileFeatureProperties>>;
export type ClusteredPointOGITileFeature = Required<GeoJSON.Feature<GeoJSON.Point, ClusteredOGITileFeatureProperties>>;

/**
 * @returns data is `PointOGITileFeature` | `ClusteredPointOGITileFeature`
 *
 * Feature must have point geometry, correct properties and
 * `properties.layerName` in  `OGIPointInfrastructureIds`.
 */
export function isPointOGITileFeature(
    data: string | Feature
): data is PointOGITileFeature | ClusteredPointOGITileFeature {
    if (!isOGITileFeature(data)) return false;
    if (!isPointFeature(data)) return false;
    const pointLayerNames = Object.values(OGIPointInfrastructureIds);
    // convert to unknown first - this is okay because we are checking for inclusion, not assuming it
    return pointLayerNames.includes(data.properties.layerName as unknown as OGIPointInfrastructureIds);
}
/**
 * @returns `data is ClusteredPointOGITileFeature`
 *
 * Feature must pass checks for `isPointOGITileFeature` and include
 * `clustered` and `point_count` properties. `properties.clustered === true`
 */
export function isClusteredPointOGITileFeature(data: string | Feature): data is ClusteredPointOGITileFeature {
    return (
        isPointOGITileFeature(data) &&
        'clustered' in data.properties &&
        data.properties.clustered &&
        'point_count' in data.properties
    );
}

export type LineOGITileFeature = Required<
    GeoJSON.Feature<GeoJSON.LineString | GeoJSON.MultiLineString, OGITileFeatureProperties>
>;

export function isLineOGITileFeature(data: string | Feature): data is LineOGITileFeature {
    if (!isOGITileFeature(data)) return false;
    return data.properties.layerName.includes(OGILayerIds.pipelines);
}

export type PolygonOGITileFeature = Required<
    GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon, OGITileFeatureProperties>
>;

export function isBasinOGITileFeature(data: string | Feature): data is PolygonOGITileFeature {
    if (!isOGITileFeature(data)) return false;
    return (
        (data.geometry.type === 'Polygon' || data.geometry.type === 'MultiPolygon') &&
        data.properties.layerName.includes(OGILayerIds.basins)
    );
}

/**
 * Mean emissions in a target (mean = total emissions / target size)
 */
export type kgHrKm2 = number;
