import { I18n } from 'next-localization';

import { DictionaryMeta, PathParams, SupportedLocale, SupportedLocaleObject } from '../types';

/**
 * Instance of internationalization object that can be used outside of React.
 */
export const i18n = I18n({});

export const supportedLocales: SupportedLocale[] = ['en'];
export const DEFAULT_LOCALE = 'en';

export const addLanguageToPathParams = (pathParams?: PathParams): PathParams => {
    if (!pathParams) {
        return supportedLocales.map((supportedLocale) => ({ params: { language: supportedLocale } }));
    }

    return pathParams.reduce((accum: PathParams, pathParam) => {
        const pathParamAndLocale = supportedLocales.map((supportedLocale) => {
            if (typeof pathParam === 'string') {
                throw new Error('strings are not supported. Use the object { params: {} } notation');
            } else {
                return { ...pathParam, params: { ...pathParam.params, language: supportedLocale } };
            }
        });
        return [...accum, ...pathParamAndLocale];
    }, []);
};

export const addLanguageAndDictionaryToProps = async <Props>(
    language: string | string[] | undefined,
    props: Props,
    dictionaryMeta: DictionaryMeta
): Promise<Props & SupportedLocaleObject> => {
    const languageLocale = getLocale(language);
    const dictionary = await getDictionary(languageLocale, dictionaryMeta);
    return { ...props, language: languageLocale, dictionary };
};

const getLocale = (language: string | string[] | undefined): SupportedLocale => {
    let languageLocale = DEFAULT_LOCALE;

    if (Array.isArray(language)) {
        languageLocale = language[0];
    } else if (language) {
        languageLocale = language;
    }

    if (!(supportedLocales as string[]).includes(languageLocale)) {
        languageLocale = DEFAULT_LOCALE;
    }

    return languageLocale as SupportedLocale;
};

const getDictionary = async (
    language: SupportedLocale,
    { page }: DictionaryMeta
): Promise<Pick<SupportedLocaleObject, 'dictionary'>> => {
    const pageDictionary = (await import(`../locales/${language}-${page}.json`)).default;
    const commonDictionary = (await import(`../locales/${language}-common.json`)).default;
    // The content for some pages will be in a markdown format contained in a TypeScript
    // string variable. This section of logic will load the page content and send it
    // through props.
    let pageContent: string | undefined;
    try {
        pageContent = (await import(`../locales/${language}-${page}-content.ts`)).default;
    } catch (e) {
        // Ignore error as all pages won't have content
    }
    return { ...pageDictionary, ...commonDictionary, ...(pageContent && { pageContent }) };
};
