import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HTTPResponseError, UserStateType } from '../types';
import type { RootState } from '../store';
import { fetchCurrentUser, isHTTPError } from '../utils';

export const initialUserState: UserStateType = {
    avatarSrc: null,
    error: null,
    familyName: null,
    givenName: null,
    id: null,
    loading: false
};

/**
 * User slice for controlling updates to the user state.  Autogenerates action creators and
 * action types boilerplate.
 * See https://redux-toolkit.js.org/api/createslice/
 */
export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,

    /**
     * Yes, these reducer states are really immutable, though they don't appear to be!
     * Reducer functions use immer under the hood, which lets us write simpler code (without
     * having to manually make copies of the state).
     * See https://redux-toolkit.js.org/usage/immer-reducers#immer-usage-patterns
     */
    reducers: {
        pending: (state) => {
            state.loading = true;
        },
        fulfilled: (state, action: PayloadAction<UserStateType>) => {
            return {
                ...action.payload,
                loading: false,
                error: null
            };
        },
        rejected: (state, action: PayloadAction<HTTPResponseError>) => {
            state.loading = false;
            state.error = action.payload;
        },
        /**
         * Resets back to initial state.  This is often handy to call when some components unmount.
         * In this case we need to reset the state when a user logs out.
         */
        reset: () => initialUserState
    }
});

export const { reset } = userSlice.actions;

/**
 * Helper to retrieve the user state from the store.
 */
export const selectUser = (state: RootState): UserStateType => state.user;

/**
 * Async thunk that returns the current user metadata, and fetches it when needed.
 * Thunks are the recommended way to make async updates to the store.
 * See https://redux-toolkit.js.org/api/createAsyncThunk
 */
export const getCurrentUser = createAsyncThunk('user', async (args, thunkApi) => {
    const { getState, rejectWithValue } = thunkApi;
    const state = getState() as RootState;
    const { id } = state.user;

    // Return cached user already in the store.
    if (id !== null) {
        return state.user;
    }

    // Fetch the user fresh from the server.
    try {
        const response = await fetchCurrentUser();
        return response;
    } catch (error) {
        const errorMessage = isHTTPError(error) ? `${error.code}: ${error.message}` : error;
        return rejectWithValue(errorMessage);
    }
});

export default userSlice.reducer;
