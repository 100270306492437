import GeoJSON from 'geojson/index';

type SimpleGeometry = Exclude<GeoJSON.Geometry, GeoJSON.GeometryCollection>;

// adds a Feature type where `properties` and `id` are always present
export type FeatureWithProperties<
    G extends SimpleGeometry | null = SimpleGeometry,
    P = GeoJSON.GeoJsonProperties
> = Required<Pick<GeoJSON.Feature<G, NonNullable<P>>, 'properties' | 'id'>> &
    Omit<GeoJSON.Feature<G, NonNullable<P>>, 'properties'>;

export function isGeoJsonFeature(data: unknown): data is GeoJSON.Feature {
    if (!data) return false;
    if (typeof data !== 'object') return false;
    if (Array.isArray(data)) return false;
    return 'type' in data && 'geometry' in data && 'properties' in data;
}

function isGeoJsonObject(data: unknown): data is GeoJSON.GeoJsonObject {
    if (typeof data !== 'object' || !data) return false;
    return 'type' in data && typeof data.type === 'string';
}

export function isPointFeature(data: unknown): data is GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties> {
    if (!isGeoJsonFeature(data)) return false;
    if (!data.geometry) return false;
    return isPoint(data.geometry);
}

function isPoint(data: unknown): data is GeoJSON.Point {
    if (!isGeoJsonObject(data)) return false;
    return (
        data.type === 'Point' &&
        'coordinates' in data &&
        Array.isArray(data.coordinates) &&
        typeof data.coordinates[0] === 'number'
    );
}
