const isURL = require('validator/lib/isURL');

/**
 * Utility to convert verify that strings are urls and returns them
 * if so. Will return undefined if it is not a url. It also checks that
 * the url length is < 2083. 2083 is an arbitrary value based on
 * what IE can support. Even though we do not support IE, it is an
 * acceptable length.
 *
 * @example
 * envToUrl('https://test.com')
 * // returns 'https://test.com'
 *
 * @note http://localhost:port is not a valid url.
 * Instead, use http://0.0.0.0:port
 *
 */
const envToUrl = (environmentVariable, options = {}) => {
    return environmentVariable && isURL(environmentVariable, options) ? environmentVariable : undefined;
};

/**
 * Verifies a GCS bucket url is valid.  Returns undefined when invalid.
 */
const envToGCSBucket = (environmentVariable) => {
    return process.env.NODE_ENV === 'development'
        ? environmentVariable
        : envToUrl(environmentVariable, { protocols: ['gs'], require_tld: false });
};

module.exports = { envToGCSBucket, envToUrl };
