/**
 * A singleton that allows a function to be called from another part
 * of the code. Currently, one function can be called and will be
 * immediately unsubscribed.
 */
export class Notifier {
    private static instance: Notifier;
    private subscription: (() => void) | undefined;

    constructor() {
        // If we have called the constructor already,
        // return the previously created instance
        if (Notifier.instance) {
            return Notifier.instance;
        }
        Notifier.instance = this;
    }

    // Given a function, add it to a subscriptions
    // array to be called when notify is called.
    subscribeOnce(fn: () => void) {
        this.subscription = fn;
    }

    // Call the functions in the subscriptions array
    notify() {
        this.subscription?.();
        this.subscription = undefined;
    }
}
