import { Feature, GeoJsonProperties, Geometry } from 'geojson';

/**
 * Available STAC Collections (see `/stac/collections` endpoint)
 */
export enum STACCollection {
    MethaneAIR_Level3 = 'MethaneAIR_Level3',
    MethaneAIR_Level4 = 'MethaneAIR_Level4',
    MethaneSAT_Level3 = 'MethaneSAT_Level3',
    MethaneSAT_Level4 = 'MethaneSAT_Level4'
}

export interface PlumeProperties {
    start_datetime: string;
    end_datetime: string;
    instrument: string;
    basin: string;
}

/**
 * List of Fields to include/exclude
 */
export type StacSearchField = {
    /** fields to include */
    include?: string[];
    /** fields to exclude */
    exclude?: string[];
};

/**
 * Enum for maintaining directions for search
 */
export enum SortDirection {
    Ascending = 'asc',
    Decending = 'desc'
}

/**
 * Object to specify how to sort results
 */
export type StacSearchSorter = {
    /** field: field names to use to sort result */
    field: string;
    /** direction the field should be sorted */
    direction: SortDirection;
};

export type StacFilterProperty = {
    property: string;
};

// There are a multitiude of other options that can go here
// These should be added later when/as needed
export type StacFilterValue = string;

export type StacFilterArg = StacFilterProperty | [StacFilterProperty, StacFilterValue];

export type StacFilter = {
    // filter operation
    op: string;
    args: StacFilterArg;
};

/**
 * Search Json to send to Stac to return Items
 * for more information about STAC properties and structure
 * for searches see:
 * https://github.com/radiantearth/stac-spec/blob/master/item-spec/item-spec.md
 */
export type StacSearch = {
    /** collections to search on */
    collections?: string[];
    /** ids to search on */
    ids?: string[];
    /** the number of results to return */
    limit?: number;
    /** Bounding box in the form of: min lng, min lat, max lng, max lat */
    bbox?: StacFormattedBbox;
    /** Which fields to return from the search*/
    fields?: StacSearchField;
    /** Filter results by properties and features */
    filter?: StacFilter;
    /** language used by the filter */
    'filter-lang'?: string;
    /** Which parameters to sort the results by */
    sortby?: StacSearchSorter[];
    /** Time range to return results from "from_date/to_date"
     * ex: YYYY-MM-DDTHH:mm:SSZ/YYYY-MM-DDTHH:mm:SSZ
     */
    datetime?: string;
    /** Point the search should intersect with */
    intersects?: StacSearchIntersect;
};

/**
 * Link to Stac Item
 */
export type StacItemLink = {
    rel?: string;
    href?: string;
    type?: string;
};

/**
 * Asset definition
 */
export type StacAsset = {
    href: string;
    type: string;
    title?: string;
    roles?: string[];
};

export type StacFeature<G extends Geometry = Geometry, P = GeoJsonProperties> = Feature<G, P> & {
    collection?: string;
    stac_version?: string;
    id: string;
    links?: StacItemLink[];
    assets?: {
        COG?: StacAsset;
        GeoJSON?: StacAsset;
    };
    // Index in a Feature Collection
    index?: number;
};

/**
 * Modified Feature Collection returned from Stac Search
 */
export type StacSearchResponse<G extends Geometry = Geometry, P = GeoJsonProperties> = {
    type: string;
    features?: StacFeature<G, P>[];
    links?: StacItemLink[];
    context?: {
        limit?: number;
        returned?: number;
    };
    collection?: string;
    id?: string;
};

/**
 * Geometry the search should intersect with
 */
export type StacSearchIntersect = {
    type: string;
    coordinates?: number[] | number[][];
    bbox?: number[];
};

export type StacFormattedBbox = [number, number, number, number];
