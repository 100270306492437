import { getGriddedDataAndPropertiesFromStac } from '../hooks';
import { AreaEmissionsProducts, PickInfoToPrioritize, Platforms } from '../types';
import { TileLayerWithItemId } from '@methanesat/maps';

/**
 * Using the raster area emission layer's PickInfoToPriorTize object,
 * get methane metadata from TiTiler, compose it into a GeoJSON feature and return a promise for it.
 *
 * Return a promise for null if we cannot get the flight id,
 * or if TiTiler does not return valid methane data.
 */
export const getRasterData = async (
    info: PickInfoToPrioritize,
    endDate: Date,
    product: AreaEmissionsProducts,
    platform: Platforms
) => {
    // load bounds for raster tile
    const lng = info.coordinate[0];
    const lat = info.coordinate[1];

    const itemId = (info.layer as TileLayerWithItemId).itemId;

    // Fetch the methane value along with the properties
    const dataObj = await getGriddedDataAndPropertiesFromStac(lng, lat, itemId, product, platform);
    if (dataObj) {
        return dataObj;
    }

    return null;
};
