import { setDeckGLAlpha } from '..';
import { Color } from '../types';

/**
 * Computes linear color stop values. Does not guarantee whole numbers. Any rounding necessary or desired should be
 * done after the fact.
 * @example
 * getLinearColorStops(5, 0, 20);
 * -> [0, 5, 10, 15, 20]
 * getLinearColorStops(11, 0, 100);
 * -> [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
 */
export const getLinearColorStops = (stopCount: number, min: number, max: number): number[] => {
    const stepSize = (max - min) / (stopCount - 1);
    return Array(stopCount)
        .fill(undefined)
        .map((_v, idx) => idx * stepSize + min);
};

/**
 * Lighten/darken an rgb color
 * @param color
 * @param lightness factor ( < 1) to proportaionally attribute to the rgb factors. Will darken color if greater than 1
 * @returns a lighter/darker version of the color
 */
export const lightenColor = (color: Color, lightness: number) => {
    let colorArray = color as number[];
    // Scale the rgb colors to lighten it
    colorArray = [
        Math.min(colorArray[0] / lightness, 255),
        Math.min(colorArray[1] / lightness, 255),
        Math.min(colorArray[2] / lightness, 255)
    ];
    color = colorArray as Color;
    return setDeckGLAlpha(color, 255);
};
