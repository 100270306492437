import { createSelectorCreator, lruMemoize } from '@reduxjs/toolkit';
import { Color, Unit } from '@methanesat/maps';
import { categoricalColors, colorArrayToHex, hexToColorArray } from '@methanesat/colors';
import { ZOOM_THRESHOLDS } from '../../../../consts';
import { AreaEmissionsProducts, MethaneLayerIds, OGILayerIds } from '../../../../types';
import { RootState } from 'packages/app/src/store';
import { getPlumeRadiusRateOfChangeScale } from '../../../../utils';
import { INFRASTRUCTURE_DYNAMIC_TILE_URL, INFRASTRUCTURE_STATIC_TILE_URL } from '../../../../environmentVariables';
import {
    isWithinZoomBounds,
    selectFlooredZoom,
    selectBasinWithinZoom,
    selectInfrastructureWithinZoom,
    selectTargetWithinZoom,
    selectPlumeFluxWithinZoom,
    selectZoomThresholds,
    selectZoomAtHundreth
} from './viewStateSelectors';

const createSelector = createSelectorCreator({ memoize: lruMemoize, memoizeOptions: { maxSize: 1 } });

//Methane layer selectors
export const selectMethaneLayerConfig = (state: RootState) => state.pages.emissions.layers.methaneLayers;

export const selectMethaneProduct = (state: RootState) =>
    selectMethaneLayerConfig(state)[MethaneLayerIds.areaEmissionRaster].product;

export const selectMethaneProductIsL4 = (state: RootState) => {
    return selectMethaneProduct(state) === AreaEmissionsProducts.l4;
};

// area emissions data
export const selectMethaneRasterLayerConfig = (state: RootState) =>
    selectMethaneLayerConfig(state)[MethaneLayerIds.areaEmissionRaster];
export const selectPlumeFluxLayerConfig = (state: RootState) =>
    selectMethaneLayerConfig(state)[MethaneLayerIds.plumeEmissionRate];
// infrastructure
export const selectTargetLayerConfig = (state: RootState) => selectMethaneLayerConfig(state)[MethaneLayerIds.targets];

export const selectMethaneRasterLayerHighlightColor = (state: RootState) =>
    selectMethaneRasterLayerConfig(state).highlightColor;

//selectors for whether or not the page should fetch data for the given layer.
const selectPlumeFluxLayerVisible = (state: RootState) => {
    const { enabled } = selectPlumeFluxLayerConfig(state);
    return enabled && selectPlumeFluxWithinZoom(state);
};
export const selectShouldFetchPlumeFluxLayerData = selectPlumeFluxLayerVisible;

const selectTargetLayerVisible = (state: RootState) => {
    const { enabled } = selectTargetLayerConfig(state);
    return enabled && selectTargetWithinZoom(state);
};
export const selectShouldFetchTargetLayerData = selectTargetLayerVisible;

//OGI layer selectors
export const selectOGILayerConfig = (state: RootState) => state.pages.emissions.layers.ogiLayers.layerState;

const selectPipelineLayerConfig = (state: RootState) => selectOGILayerConfig(state)[OGILayerIds.pipelines];
const selectPointInfrastructureLayerConfig = (state: RootState) =>
    selectOGILayerConfig(state)[OGILayerIds.pointInfrastructure];

export const selectInfrastructureEnabled = (state: RootState) => {
    return selectPointInfrastructureLayerConfig(state).enabled && selectPipelineLayerConfig(state).enabled;
};

/**
 * Selector for plume methane emissions layer.
 */
export const selectPlumeEmissionRateLayerConfig = createSelector(
    [
        (state) => selectMethaneLayerConfig(state)[MethaneLayerIds.plumeEmissionRate],
        (state) => isWithinZoomBounds(selectZoomThresholds(state), MethaneLayerIds.plumeEmissionRate),
        selectZoomAtHundreth
    ],
    (layerConfig, withinBounds, zoomAtHundreth) => {
        const { enabled } = layerConfig;
        const visible = enabled && withinBounds;

        // The following section controls the min and max radius of the
        // point sources. The numbers below were determined by looking at the
        // map and seeing what looks good. The numbers here assume that we are
        // using sizeUnits: "common" for the layer. "common" means that the point
        // source will stay the same size regardless of zoom level. This allows us
        // to adjust the size of the point smoothly based on zoom level.
        // https://deck.gl/docs/developer-guide/coordinate-systems#dimensions

        // By visually looking at the map, this zoom level was determined to be
        // the best for the rate of change scale.
        const maxZoomForRateChange = 14;

        // At the minimum zoom level for plumes, this was determined to be the
        // radius size that we visually want. This is in common units.
        const lowestZoomIdealSize = 0.125;
        // At the highest zoom level for plumes, this was determined to be the
        // radius size that we visually want. This is in common units.
        const highestZoomIdealSize = 0.0009;

        // Get a multiplier for a zoom level given the zoom level
        // We multiply the multiplier to the lowestZoomIdealSize to get
        // the radius of the plume. Given this scale, if we were to use
        // common units all the way to zoom level maxZoomForRateChange,
        // the value lowestZoomIdealSize * multiplier would be the highestZoomIdealSize
        const minimumRadiusScale = getPlumeRadiusRateOfChangeScale(
            [ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_PLUME_FLUX, maxZoomForRateChange],
            // Our min radius starts at the lowestZoomIdealSize, so we want to
            // multiple by 1 to begin with so we get lowestZoomIdealSize
            1,
            // This calculation represents the the value we want to multiply by to reach
            // highestZoomIdealSize at the highest zoom level
            highestZoomIdealSize / lowestZoomIdealSize
        );

        // Pass in the zoom to figure out what the multiplier should be
        const multiplier = minimumRadiusScale(zoomAtHundreth);
        // Derive the radius given the multiplier
        const radius = lowestZoomIdealSize * multiplier;

        // This represents the point at which point infrastructure switches from
        // aggregated to individual points
        const zoomBreakpoint = 11.51;

        // The size, in meters, that we want each plume to be on the map
        const plumeMeterSize = 400;

        // When we hit the zoom breakpoint, we want to switch from a size
        // derived from zoom to a fixed meter size. This means that we won't
        // hit the highestZoomIdealSize. This is fine as we use the highestZoomIdealSize
        // to derive the rate at which the plume size should change when we use common
        // units.
        const sizeAndUnits: { getSize: number; sizeUnits: Unit } = {
            getSize: zoomAtHundreth < zoomBreakpoint ? radius : plumeMeterSize,
            sizeUnits: zoomAtHundreth < zoomBreakpoint ? 'common' : 'meters'
        };

        return {
            ...layerConfig,
            ...sizeAndUnits,
            visible,
            id: MethaneLayerIds.plumeEmissionRate
        };
    }
);

export const selectMethaneTargetLayerConfig = createSelector(
    [(state) => selectMethaneLayerConfig(state)[MethaneLayerIds.targets], selectTargetLayerVisible],
    (layerConfig, visible) => {
        return {
            ...layerConfig,
            visible,
            id: MethaneLayerIds.targets
        };
    }
);

// filters - general map selectors
export const selectEmissionsMapFilters = (state: RootState) => state.pages.emissions.layers.ogiLayers.filters;
export const selectEmissionsMapInfraOperatorFilter = (state: RootState) => selectEmissionsMapFilters(state).operators;
export const selectEmissionsMapInfraCategoryFilter = (state: RootState) => selectEmissionsMapFilters(state).categories;

/**
 * Get the color that we want to color the ogim infrastructure of the same company.
 * This is cached because it would return a different object reference every call otherwise.
 * @example
 * selectOgimColorByCompany(state)
 * // returns {[key: operator_id]: hexColor}
 */
export const selectOgimColorByCompany = createSelector(
    [
        // Get the filtered ogim infrastructure
        (state) => selectEmissionsMapFilters(state).ogimFeatures
    ],
    (ogimFeatures) => {
        // Get the colors that we want to color the infrastructure
        const categoricalColorKeys = Object.keys(categoricalColors) as (keyof typeof categoricalColors)[];
        const toReturn: { [key: number]: string } = {};

        let colorIndex = 0;

        ogimFeatures.forEach(({ operators }) => {
            // Assume operators[0] is the most recent operator
            const { operator_id } = (operators && operators[0]) ?? { operator_id: undefined };
            // If there isn't an operator id entry for a color, add one
            if (operator_id && !toReturn[operator_id]) {
                toReturn[operator_id] = colorArrayToHex(categoricalColors[categoricalColorKeys[colorIndex]]);

                colorIndex += 1;
                // If the color index would overflow the color arrays, reuse colors
                if (colorIndex === categoricalColorKeys.length) {
                    colorIndex = 0;
                }
            }
        });
        return toReturn;
    }
);

/**
 * Get the color that we want to color the ogim infrastructure of the same company
 * this time, by ogim id. This is cached because it would return a different object
 * reference every call otherwise.
 * @example
 * selectCompanyColorByOgim(state)
 * // returns {[key: ogim_id]: ColorArray}
 */
const selectCompanyColorByOgim = createSelector(
    [
        // Get object of company to hex color
        selectOgimColorByCompany,
        // Get all the ogim we want to color
        (state) => selectEmissionsMapFilters(state).ogimFeatures
    ],
    (ogimColorByCompany, ogimFeatures) => {
        const companyColorbyOgim: { [key: string | number]: Color } = {};
        ogimFeatures.forEach(({ ogim_id, operators }) => {
            // Assume operators[0] is the most recent operator
            const { operator_id } = (operators && operators[0]) ?? { operator_id: undefined };
            // Assign the same color to the ogi infrastructure as its company
            if (operator_id) {
                companyColorbyOgim[ogim_id] = hexToColorArray(ogimColorByCompany[operator_id]);
            }
        });
        return companyColorbyOgim;
    }
);

export const selectInfrastructureTileLayerConfig = createSelector(
    // Only listen to changes in state we care about
    [
        selectPointInfrastructureLayerConfig,
        selectPipelineLayerConfig,
        (state) => selectOGILayerConfig(state)[OGILayerIds.basins],
        (state) => selectOGILayerConfig(state)[OGILayerIds.tileInfrastructure],
        selectFlooredZoom,
        selectEmissionsMapFilters,
        selectCompanyColorByOgim,
        selectBasinWithinZoom,
        selectInfrastructureWithinZoom
    ],
    (
        pointInfraConfig,
        pipelineConfig,
        polygonInfraConfig,
        { enabled: visible, highlightedFeatureId },
        zoom,
        { categories, ogimFeatures, operators },
        companyColorByOgim,
        basinWithinZoom,
        infrastructureWithinZoom
    ) => {
        const operatorIds = operators.map(({ id }) => id);
        const categoryIds = categories.map(({ id }) => id);

        // request for static tiles, unfiltered
        let data = `${INFRASTRUCTURE_STATIC_TILE_URL}/{z}/{x}/{y}`;

        // If we are filtering by specific ogim ids
        // AND have zoomed in far enough to see OGI
        if (ogimFeatures.length > 0 && zoom >= ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_OGI) {
            data = `${INFRASTRUCTURE_DYNAMIC_TILE_URL}/{z}/{x}/{y}?ogim_id=${encodeURIComponent(
                `[${ogimFeatures.map(({ ogim_id }) => ogim_id)}]`
            )}`;
        } else if (operatorIds && operatorIds.length && zoom >= ZOOM_THRESHOLDS.MINIMUM_ZOOM_LEVEL_OGI) {
            // request for dynamic tiles, filtered by operator
            // ONLY IF we have zoomed in far enough to see OGI
            //
            // operator_id list must be URI encoded.
            // see description of query_params for martin: https://github.com/maplibre/martin#function-sources
            data = `${INFRASTRUCTURE_DYNAMIC_TILE_URL}/{z}/{x}/{y}?operator_id=${encodeURIComponent(`[${operatorIds}]`)}`;
        }

        const basinVisibility = polygonInfraConfig.enabled && basinWithinZoom;
        const pointInfraVisibility = pointInfraConfig.enabled && infrastructureWithinZoom;
        const pipelineInfraVisibility = pipelineConfig.enabled && infrastructureWithinZoom;

        return {
            basinVisibility,
            categories,
            companyColorByOgim,
            data,
            categoryIds,
            highlightedFeatureId,
            ogimFeatures,
            pipelineConfig,
            pipelineInfraVisibility,
            pointInfraConfig,
            pointInfraVisibility,
            polygonInfraConfig,
            visible,
            zoom
        };
    }
);

// Memoized layer selector
export const selectEmissionsMapLayerConfigs = createSelector(
    [selectMethaneLayerConfig, selectOGILayerConfig],
    (methaneLayers, ogiLayers) => ({
        ...methaneLayers,
        ...ogiLayers
    })
);
