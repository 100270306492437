import { RGB, Color } from '../types';

/**
 * @param baseColor RGBA | RGB
 * @param highlightColor RGBA | RGB
 * @returns RGBA
 * 
 * Returns a color array describing the color that would be achieved by layering `highlightColor` on top of `baseColor`.
 * 
 * If `highlightColor` is opaque (alpha = 255), `highlightColor` will be returned.
 * If `highlightColor` is partially transparent, the returned color will be a blend of the two colors, based on their relative alpha values.
 * 
 * Note: if either color omits the alpha value, it defaults to 255 (opaque)
 * 
 * port of a portion of luma.gl's picking_filterHighlightColor function:
 * {@link https://github.com/visgl/luma.gl/blob/master/modules/shadertools/src/modules/picking/picking.ts#L116  picking.ts}
  
  ```cpp
    float highLightAlpha = picking_uHighlightColor.a;
    float blendedAlpha = highLightAlpha + color.a * (1.0 - highLightAlpha);
    float highLightRatio = highLightAlpha / blendedAlpha;

    vec3 blendedRGB = mix(color.rgb, picking_uHighlightColor.rgb, highLightRatio);
    return vec4(blendedRGB, blendedAlpha);
  ```
*/
export function pickingFilterHighlightColor(baseColor: Color, highlightColor: Color): Color {
    const highlightAlpha = highlightColor[3] || 255;
    const baseAlpha = baseColor[3] || 255;
    const blendedAlpha = highlightAlpha + (baseAlpha * (255 - highlightAlpha)) / 255;

    const highlightRatio = highlightAlpha / blendedAlpha;

    const baseRGB = baseColor.slice(0, 3) as RGB;
    const highlightRGB = highlightColor.slice(0, 3) as RGB;
    const blendedRGB: RGB = mix(baseRGB, highlightRGB, highlightRatio);

    return [...blendedRGB, blendedAlpha] as Color;
}

/**
 * Blends two RGB colors (does not include alpha channel)
 *
 * port of glsl's mix function: https://docs.gl/el3/mix
 * Uses the formula color1 * (1 − ratio) + color2 * ratio
 */
export function mix(color1: RGB, color2: RGB, ratio: number): RGB {
    const color1Portion = color1.map((c) => c * (1 - ratio));
    const color2Portion = color2.map((c) => c * ratio);
    return color1Portion.map((c, idx) => c + color2Portion[idx]) as RGB;
}
