import { mapboxBaseStylePath } from '@methanesat/maps';
import type { RootState } from '../../../../store';

//map interface
export const selectEmissionsMapInterface = (state: RootState) => state.pages.emissions.mapInterface;

// map style
export const selectEmissionsMapStyle = (state: RootState) => selectEmissionsMapInterface(state).mapStyle;
export const selectEmissionsMapStyleUrl = (state: RootState) => {
    const key = selectEmissionsMapStyle(state);
    if (!key) return;
    return `${mapboxBaseStylePath}/${key}`;
};

//notifications
export const selectEmissionsMapNotifications = (state: RootState) => selectEmissionsMapInterface(state).notifications;
